.ticket-scanner {
	.scanner {
		margin-left: auto;
		margin-right: auto;
	}

	.scan-response {
		width: calc(100vw - 100px);
		height: calc(100vh - 78px);
		position: fixed;
		top: 78px;
		left: 100px;
		z-index: 500;
		display: flex;
		margin: 0px;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		overflow: auto;

		@media (max-width: 720px) {
			left: 0;
			top: 50px;
			width: 100vw;
			height: calc(100vh - 50px);
		}
		& > div:nth-child(2) {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		&.fadeout {
			animation: 0.32s ticketFadeOut ease-in-out;
		}

		.close-btn {
			position: absolute;
			right: 10px;
			top: 0px;
			padding: 20px;
			font-weight: bold;
			font-size: 2rem;
		}

		.booking-data {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: 30px;

			.households {
				text-align: center;
				width: 100%;
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				.household {
					margin: 20px 30px;
					width: 200px;
					& > div {
						margin-top: 20px;
					}
					h2 {
						text-transform: uppercase;
						font-weight: 900;
						margin: 30px 0px 10px 0px;
					}

					ul {
						margin: 0px 0px 0px 20px;
						padding: 0px;
						li {
							list-style: none;
							display: flex;
							align-items: center;
							margin: 5px 0px;
							button {
								display: flex;
								margin-right: 10px;
							}
							i {
								margin-top: 2.5px;
								color: var(--invalid);
							}
						}
					}
				}
			}
			.base {
				text-align: center;
				& > div {
					margin-top: 20px;
				}
			}

			p {
				font-size: 1.1rem;
			}
			.label {
				font-weight: bold;
				margin: 5px 0px;
			}
			p:not(.label) {
				margin: 0px;
			}
		}

		.response-background {
			width: calc(100vw - 100px);
			height: calc(100vh - 78px);
			position: fixed;
			top: 78px;
			left: 100px;
			animation: 0.3s ticketFadeIn ease-in-out;
			background-color: rgba(154, 205, 145, 0.95);
			-webkit-backdrop-filter: blur(4px);
			&.invalid {
				background-color: rgba(236, 131, 99, 0.95) !important;
			}

			@media (max-width: 720px) {
				left: 0;
				top: 50px;
				width: 100vw;
				height: calc(100vh - 50px);
			}
		}
		h1 {
			text-align: center;
			max-width: 400px;
			margin-top: 25vh;
		}
		& > div,
		& > h1 {
			z-index: 501;
			//animation: 0.5s ticketScrewIn ease-in-out;
		}

		.image {
			width: 40%;
			position: relative;
			float: right;
			margin-left: 10px;
			img {
				width: 100%;
				box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
				margin-top: 30px;
			}
		}
		.item {
			text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
			margin: 10px;
			.title {
				display: block;
				font-size: 1.8rem;
				font-weight: bold;
			}
			.value {
				display: inline;
				font-size: 1.3rem;

				p {
					margin: 0px;
				}
			}
		}
	}

	.spinner-wrapper {
		width: 100%;
		height: calc(100vh - 200px);
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.closing-circle {
		width: 200px;
		height: 200px;
		cursor: pointer;
		-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
		position: relative;

		i {
			font-size: 5rem;
			margin-left: auto;
			display: block;
			width: 75px;
			margin-right: auto;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: 55px;
			z-index: -1;
			opacity: 0.4;
		}

		.circular-close {
			display: block;
			margin: 10px auto;
			max-width: 80%;
			max-height: 250px;
		}

		.circle-bg {
			fill: none;
		}

		.circle {
			fill: none;
			stroke-width: 3;
			stroke-linecap: round;
			//animation: progress 1s ease-out forwards;
		}

		@keyframes progress {
			0% {
				stroke-dasharray: 0 100;
			}
		}

		.circular-close .circle {
			stroke: rgba(255, 255, 255, 0.7);
		}

		.text {
			font-size: 0.3rem;
			text-anchor: middle;
			font-weight: bold;
			user-select: none;
			-webkit-touch-callout: none;
		}
	}
}
@keyframes ticketFadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes ticketFadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@keyframes ticketScrewIn {
	0% {
		transform: scale(0);
	}
	50% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}

.ticket-table {
	.table .tbody {
		max-height: calc(100vh - 300px) !important;
	}

	.toolbar {
		display: flex;
		.toolbar-stat {
			margin: 20px;
			.title {
				font-weight: bold;
				margin: 0px;
			}
		}
	}

	.dialog-entry {
		.im {
			font-family: "iconmonstr-iconic-font";
		}

		ul {
			padding: 0px;
			margin: 0px;
			li {
				display: flex;
				list-style: none;
				margin: 10px 0px;
				align-items: center;

				button {
					margin-right: 20px;
					padding: 0px;
					display: flex;
					height: 25px;
					align-items: center;
					i {
						margin-top: 5px;
						color: var(--invalid);
						font-size: 1.2rem;
					}
				}
			}
		}
	}
}
.corona-swimming-statistics {
	.stat {
		&:first-of-type {
			.title {
				margin-top: 0px;
			}
		}
		.value-obj {
			width: 100%;
			display: flex;
			align-items: center;

			.progress-bar {
				flex-grow: 1;
				max-width: calc(100% - 50px);
				background: var(--sidebar);
				margin: 0px 10px;
				height: 15px;
				border-radius: 5px;
				position: relative;
				span {
					background: var(--highlight);
					display: block;
					height: 100%;
					border-radius: 5px;
				}
			}
			.value,
			.of {
				font-weight: bold;
			}
		}
		.title {
			font-weight: bold;
			margin: 20px 0px 5px 0px;
		}
	}
}

#root .data-entry {
	.onedash-form-component {
		margin-top: 5px;
	}
	.member-input {
		display: inline-block;
		position: relative;
		margin-top: 0px;
		position: relative;
		max-width: calc(var(--tools-input-width) + 20px);
		width: 100%;

		i {
			cursor: pointer;
			position: absolute;
			font-size: 20px;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
			line-height: 100%;
			margin-top: 10px;
			color: var(--invalid);
		}
		@media (max-width: 720px) {
			&.last-item {
				.onedash-form-component {
					width: calc(100% - 40px);
				}
			}
		}
	}
	.new-member {
		display: flex;
		align-items: center;
		cursor: pointer;
		background: var(--lightBlue);

		border-radius: 4px;
		padding: 5px 10px;
		margin-top: 10px;
		i {
			margin-right: 10px;
			color: var(--text);
		}
		p {
			font-size: 1rem;
			color: var(--text);
			font-weight: bold;
			margin: 0px;
		}
		&.disabled {
			pointer-events: none;
			background: var(--tools-light-gray) !important;
			border-color: var(--tools-gray) !important;
			i,
			p {
				color: var(--tools-text) !important;
			}
		}
		@media (min-width: 720px) {
			width: calc(100% - 60px);
			margin: 0px 10px;
		}
		&:hover {
			opacity: 0.9;
		}
	}

	.add-household {
		width: 100%;
		margin-top: 30px !important;
		@media (min-width: 720px) {
			width: calc(100% - 40px);
		}
	}
	.household {
		position: relative;
	}
	.household > div > i {
		position: absolute;
		color: var(--invalid);
		right: 20px;
	}
}
