.image-collection
	display: flex
	margin: 0px -10px
	overflow-x: auto
	padding: 10px 0px
	margin-top: -10px

	&.edit-mode
		.image
			.im-x-mark-circle
				position: absolute
				right: -9px
				top: -9px
				font-size: 18px
				color: var(--invalid)
				cursor: pointer
				display: block
				transition: 0.15s all ease-in-out
				&:hover
					transform: rotate(90deg)
					opacity: 0.9
			&:before
				position: absolute
				content: ''
				border-radius: 50%
				background: var(--background)
				width: 30px
				height: 30px
				right: -15px
				top: -15px

	.image-upload-area
		width: 100px
		height: 70px
		margin: 0px 10px
		flex-basis: 100px
		flex-shrink: 0
		cursor: pointer
		&:hover .dropzone i, &:hover .dropzone span
			color: var(--highlight)

		.dropzone
			position: relative
			border: 2px dashed var(--middleGray)
			border-radius: 4px
			display: flex
			justify-content: center
			align-items: center
			flex-direction: column
			background: var(--lightGray)
			width: calc(100% - 4px)
			height: calc(100% - 4px)

			&.accept
				border-color: var(--onedashGreen)
			&.reject
				border-color: var(--invalid)

			span
				color: var(--gray)
			i
				color: var(--gray)

	.image
		width: 100px
		flex-basis: 100px
		flex-shrink: 0
		height: 70px
		margin: 0px 10px
		position: relative

		.progress-bar
			height: 5px
			background: var(--lightGray)
			width: 100%
			position: absolute
			bottom: 0
			border-bottom-left-radius: 4px
			border-bottom-right-radius: 4px

			span
				height: 100%
				background-color: var(--highlight)
				display: block
				border-bottom-left-radius: 4px
				border-bottom-right-radius: 4px

		.image-new-indicator
			position: absolute
			left: 5px
			top: 5px
			i
				font-size: 10px
				color: white
				text-shadow: 0px 0px 5px rgba(#000,0.5)
				display: block

		img
			width: 100%
			height: 100%
			border-radius: 4px
			box-shadow: 0px 0px 3px rgba(0,0,0,.5)
			object-fit: cover
		i
			display: none
