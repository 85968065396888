.dropdown {
	position: relative;
	display: inline-block;

	button {
		border: none;
		cursor: pointer;
		padding: 0px;
	}

	.dropdown-content {
		$padding: 20px;
		padding: 10px $padding;
		width: calc(100% - #{$padding * 2 + 10});
		margin-top: 25px;
		display: none;
		position: absolute;
		background-color: var(--background);
		min-width: 160px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		z-index: 1;
		&.show {
			display: block;
		}
	}
}
