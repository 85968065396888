#root .gaestebeitrag-erfassung
	.onedash-card
		padding: 30px
		width: calc(100% - 80px)
	.table-head, .table-body
		display: grid
		grid-template-columns: 3fr 60px 2fr 2fr 70px
		align-items: center
		gap: 0px 25px
		.sum
			text-align: right
		.price span, .sum span
			display: none

	@media screen and ( max-width: 720px )
		.table-body
			display: block
		.sum
			text-align: left !important
			font-weight: bold
			margin-top: 15px !important
			span
				display: inline !important

		.price
			display: inline-block
			margin-left: 10px
			span
				display: inline !important
		.label
			color: var(--highlight)
			margin: 40px 0px 5px 0px
			font-weight: bold
			font-size: 1.2rem
			font-style: normal
			display: inline-block

		.onedash-input
			display: block
		.sum
			margin-top: 10px
	.vacation-toggle
		margin-top: 50px
		max-width: 500px !important
		display: flex
	.table-head
		margin-top: 30px
		p
			font-weight: bold

	.table-body
		.label
			font-style: italic

	.gaestebeitrag-form
		margin-top: 20px

	.total
		margin-top: 20px
		padding-top: 10px
		border-top: 1px double black
		position: relative
		display: flex
		justify-content: space-between
		&::before
			content: " "
			position: absolute
			top: -4px
			left: 0px
			right: 0px
			height: 1px
			background: black
		.label
			font-weight: bold
			font-size: 1.3rem
		.price
			font-weight: bold
			font-size: 1.3rem
	.toggles
		margin-top: 50px
	.onedash-boolean
		max-width: 100%
		.onedash-children
			font-size: 1rem
			line-height: 110%

	.submit-btn
		margin-top: 30px
		width: 200px
		.button-spinner > div
			background-color: var(--highlight)

	@media print
		.card-content > p
			display: none
		.onedash-button-v2
			display: none
		.vacation-toggle
			display: inline-flex
			margin: 0px

		.onedash-select
			margin: 0px
			max-width: 150px
			.placeholder-required
				display: none

.hide-print
	.print-btn
		margin-top: 20px
		display: block
		margin-left: auto
		margin-right: auto

	@media print
		display: none !important

#root .gaestebeitrag-statistik
	.link-erfassung, .is-vacation
		display: inline-block
		background: var(--highlight)
		text-decoration: none
		padding: 5px 10px
		border-radius: 4px
		margin: -5px 0px
		color: white
	.is-vacation
		background: var(--lightBlue)
#root .gaestebeitrag-stat-dialog
	.vacation-toggle
		max-width: 100%
	.total, .old-total
		margin-top: 20px
		padding: 10px
		position: relative
		display: flex
		justify-content: space-between

		.label
			font-weight: bold
			font-size: 1.3rem
		.price
			font-weight: bold
			font-size: 1.3rem

	.old-total
		margin-bottom: -30px
		.label,.price
			color: var(--middleGray)
			font-size: 1.1rem
	.cell.sum, .column.sum
		text-align: right
		font-weight: bold

	.onedash-table-v2.is-mobile
		.cell.sum, .column.sum
			text-align: left
		.column.sum .value
			color: var(--highlight)

#root .gaestebeitrag-monat
	.not-entered, .is-vacation
		display: inline-block
		background: var(--invalid)
		padding: 5px 10px
		margin: 0px
		border-radius: 4px
		color: white
		&.is-vacation
			background: var(--lightBlue)
	.toolbar
		display: flex
		flex-wrap: wrap
		justify-content: space-between
		margin: 0px 10px
#root .betriebsuebersicht-dialog
	.dialog-content
		.entry-form
			display: flex
			flex-wrap: wrap
			justify-content: space-between
			& > div
				width: 100%
				max-width: 300px
		h3
			color: var(--highlight)

	.account .delete-btn
		color: var(--invalid)
		padding: 0px

#root .betriebsinformationen
	display: flex
	align-items: flex-start
	flex-wrap: wrap
	.form-buttons
		margin-top: 10px
		margin-left: 10px
	.onedash-table-v2
		.table-body
			max-height: 440px
			overflow-y: auto
	.entry-form
		display: flex
		flex-wrap: wrap
		justify-content: space-between
		& > div
			width: calc(100% - 20px)
			max-width: 280px
			margin: 0px 10px
		h3
			color: var(--highlight)
			margin: 10px 0px 5px 0px

.monatsansicht-export .button-spinner > div
	background-color: var(--highlight)
