
.error-animation, .success-animation
	text-align: center
	h1
		margin-top: 30px
	.children
		margin-top: 10px
		line-height: 150%

	$circle-length: 151px
	$check-length: 36px

	#animation
		margin-left: auto
		margin-right: auto
		display: block

		&.visible
			animation: 1s ease-out 0s 1 both scaleAnimation
			#animation__circle
				animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle, 0.3s linear 0.9s 1 both fadeOut
			#animation__check
				animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck, 0.3s linear 0.9s 1 both fadeOut
			#animation__result
				animation: 0.3s linear 0.9s both fadeIn

		&__circle
			stroke-dasharray: $circle-length $circle-length
			stroke: var(--highlight)

		&__check
			stroke-dasharray: $check-length $check-length
			stroke: var(--highlight)

		&__x
			stroke-dasharray: $check-length $check-length
			stroke: var(--highlight)

		&__result
			fill: var(--highlight)
			opacity: 0

	@keyframes scaleAnimation
		0%
			opacity: 0
			transform: scale(1.5)

		100%
			opacity: 1
			transform: scale(1)

	@keyframes drawCircle
		0%
			stroke-dashoffset: $circle-length

		100%
			stroke-dashoffset: 0

	@keyframes drawCheck
		0%
			stroke-dashoffset: $check-length

		100%
			stroke-dashoffset: 0

	@keyframes fadeOut
		0%
			opacity: 1

		100%
			opacity: 0

	@keyframes fadeIn
		0%
			opacity: 0

		100%
			opacity: 1
.error-animation
	#animation
		&__circle
			stroke: var(--invalid)
		&__check
			stroke: var(--invalid)
		&__x
			stroke: var(--invalid)
		&__result
			fill: var(--invalid)
