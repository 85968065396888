#root .sidebar-wrapper {
	@media (min-width: 720px) {
		width: 270px;
		max-width: 270px;
		right: 0px;
		top: 90px;
		position: fixed;
		height: calc(100vh - 90px);
		transition: 0.15s all ease-in-out;
		background: var(--background);
		box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);

		&.collapsed {
			max-width: 0px;
			.sidebar-toggle-btn {
				left: -35px;
				&:hover {
					left: -40px;
				}
			}
		}
		.sidebar {
			padding: 20px;
			width: 230px;
			overflow-x: hidden;
			overflow-y: auto;
			height: calc(100vh - 77px - 80px);

			h1 {
				font-size: 1.4rem;
				margin: 10px 0px;
				padding: 0px;
			}
			.onedash-form-component {
				margin: 10px 0px 20px 0px;
				max-width: 100%;
				width: 100%;
			}

			.onedash-card {
				padding: 0px;
				background: none;
				margin: 20px 0px;
				width: 100%;
				box-shadow: none;
				&:first-of-type {
					margin-top: 0px;
				}
			}
			.onedash-button.full-width {
				width: 100%;
				margin: 10px 0px;
			}

			.side-bar-content {
				overflow-x: hidden;

				width: 100%;
			}
		}
		.sidebar-toggle-btn {
			position: absolute;
			height: 50px;
			left: -35px;
			width: 35px;
			background: var(--highlight);
			border-radius: 30px 0px 0px 30px;
			top: 50%;
			transform: translateY(calc(-50% - 78px));
			display: flex;
			transition: 0.15s all ease-in-out;
			i {
				margin-left: 2px;
				transform: rotate(90deg);
			}
			&:hover {
				left: -40px;
				width: 40px;
			}
		}
	}
	@media (max-width: 720px) {
		.sidebar-toggle-btn {
			display: none;
		}
		.sidebar {
			transition: 0.15s all ease-in-out;
			max-height: 200vh;
		}
		& > .mobile-toggle {
			margin: 10px;
			padding: 10px 20px;
			background: var(--lightGray);
			color: var(--gray);
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&.visible i {
				transform: rotate(180deg);
			}
			p {
				margin: 0px;
			}
			i {
				transition: 0.15s all ease-in-out;
				font-size: 15px;
				color: var(--middleGray);
			}
		}

		&.collapsed {
			.sidebar {
				max-height: 0px;
				overflow: hidden;
			}
		}
	}
}
