#root .grouped-list
	display: flex
	flex-direction: column
	.group
		padding: 0px 20px

		&.collapsed
			.items
				max-height: 0px
			.header i
				transform: rotate(180deg)

#root .grouped-list, #root .list
	.header
		display: flex
		align-items: center
		cursor: pointer
		border-bottom: 1px solid var(--lightGray)
		padding: 5px 0px
		margin: 10px 0px

		.im-angle-down
			font-size: 12px
			color: var(--highlight)
			transition: 0.15s all linear
		h2
			font-size: 15px
			margin-left: 10px
			color: var(--highlight)

			span
				opacity: 0.6
				color: var(--highlight)

	.items
		overflow-y: auto
		max-height: 400px
		transition: 0.3s all linear
		margin: 0px -10px

		.list-item
			display: flex
			padding: 10px 0px
			cursor: pointer
			margin: 2.5px 10px
			position: relative
			z-index: 1

			&.selected
				&::after
					z-index: -1
					position: absolute
					content: ''
					width: calc(100% + 20px)
					height: 100%
					background: var(--highlightLight)
					top: 0
					left: -10px
					border-radius: 6px
				.value, .id, .date i, .date span
					color: var(--highlight) !important

			& > .value
				flex: 1
				display: flex
				align-items: center
				flex-basis: 150px
				flex-shrink: 0
			& > .details
				display: flex
				align-items: center
				flex-shrink: 1
				min-width: 0
				.id
					margin: 0px 30px
					font-weight: bold
					color: var(--gray)
					white-space: nowrap
					overflow: hidden
					text-overflow: ellipsis
					flex-shrink: 1

				.date
					display: flex
					align-items: center

					i
						font-size: 15px
						color: var(--middleGray)

					span
						margin-left: 10px
						color: var(--middleGray)
				.user-icon
					margin-left: 10px
					transform: scale(0.8)

				img
					width: 35px
					height: 35px
					border-radius: 50%

#root .list .header
	cursor: auto
#root .list
	padding: 0px 20px

	.items
		max-height: calc(100vh - 215px)

	.header
		display: flex
		justify-content: space-between
		h2
			margin-left: 0px

		& > div
			display: flex
			align-items: center

			.onedash-input
				margin: 0px
				margin-right: 10px
				input
					margin: 0px

			.add-icon
				margin: 0px
				padding: 0px 7.5px

				&:hover i
					opacity: 0.9
					transform: rotate(90deg)

				i
					font-size: 22px
					color: var(--highlight)
					transition: 0.15s all ease-in-out
