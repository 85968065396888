#root .users
	.header
		.onedash-input
			input
				border: none
				border-radius: 20px
			.search-icon
				margin-top: -7px
				margin-right: 2.5px

	
	#user-account-info
		.user-picture
			width: 120px
			height: 120px
			border-radius: 60px
			margin-left: 20px
		.content > div
			margin: 0px
			&:first-of-type
				margin-top: 10px
			&:last-of-type
				margin-left: 25px

		.name-split
			margin: 0px
			& > div:first-of-type
				margin-right: 5px
			input
				max-width: 100px
