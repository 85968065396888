#root .head-toolbar {
	.onedash-button.style-twenty.btn-light {
		background: none;
		border: 2px solid white;
		i {
			color: white;
			cursor: pointer;
			transition: 0.3s all ease-in-out;
			&:hover {
				opacity: 0.7;
			}
		}
	}
	h1 {
		font-size: 1.3rem;
		text-transform: upper;
	}
}
