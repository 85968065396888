html,
body {
	width: 100vw;
	height: calc(var(--wh) * 100);
	padding: 0px;
	margin: 0px;
	position: relative;
	font-size: 14px;
	font-weight: 500;
	font-family: "Raleway", sans-serif;
	overflow-x: hidden;
	overflow-y: hidden;
	* {
		font-family: "Raleway", sans-serif;
		color: var(--text);
		transition: 0.15s background ease-in-out, 0.15s color ease-in-out, 0.15s border-color ease-in-out;
		-webkit-tap-highlight-color: transparent;
	}

	background: var(--pageBackground);
	color: var(--text);

	button {
		background: none;
		border: none;
		border-radius: 0px;
		cursor: pointer;
		&:focus {
			outline: none;
		}
		&:disabled {
			pointer-events: none;
		}
	}

	h1 {
		font-size: 1.5rem;
	}
	h1.onedash-logo {
		font-size: 2rem;
		& > span {
			&:nth-child(1) {
				color: var(--onedashGray);
			}
			&:nth-child(2) {
				color: var(--onedashOrange);
			}
			&:nth-child(3) {
				color: var(--onedashGreen);
			}
		}
		&.white {
			& > span {
				&:nth-child(1) {
					color: white;
				}
			}
		}
	}

	/**
	*			SCOLLBAR
	*/
	@media (min-width: 720px) {
		::-webkit-scrollbar {
			width: var(--scrollbar-width);
			height: var(--scrollbar-width);
		}
	}
	@media (min-width: 720px) {
		::-webkit-scrollbar-thumb {
			background-color: var(--scrollbar-thumb);
			border-radius: 6px;
			border: 3px solid var(--scrollbar-bg);
		}
	}
	@media (min-width: 720px) {
		::-webkit-scrollbar-track {
			background: var(--scrollbar-bg);
		}
	}

	*:focus {
		outline: none;
	}
}
.btn.highlight-btn {
	padding: 7.5px 20px;
	text-transform: uppercase;
	font-size: 1rem;
	cursor: pointer;
	border-radius: 7.5px;
	box-shadow: none;
	border: 2px solid;
	outline: none;
	font-weight: 700;
	transition: 0.15s all ease-in-out;
	background: var(--highlight);
	border-color: var(--highlight);
	color: #fff;
	&:hover {
		color: var(--highlight);
		background: none;
	}
	&:disabled {
		background: var(--lightGray);
		border-color: var(--middleGray);
		color: var(--gray);
	}
}
.btn.delete {
	cursor: pointer;
	transition: 0.15s all ease-in-out;
	background: none;
	border: none;
	&:focus {
		outline: none;
	}
	&:hover {
		opacity: 0.7;
	}
	i {
		font-size: 15px;
		color: var(--invalid);
		path {
			fill: var(--invalid);
		}
	}
}
span.true::before {
	content: "Ja";
	display: inline-block;
	background: rgb(30, 155, 67);
	padding: 5px 10px;
	border-radius: 4px;
	color: white;
	font-weight: bold;
}
span.false::before {
	content: "Nein";
	display: inline-block;
	background: var(--invalid);
	padding: 5px 10px;
	border-radius: 4px;
	color: white;
	font-weight: bold;
}
.grid-split {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 10px;
}
.grid-split-3-icon {
	display: grid;
	grid-template-columns: 1fr 1fr 50px;
	column-gap: 10px;
	align-items: center;
	.btn.delete {
		margin-top: 40px;
	}
}
.grid-split-label {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 10px;
	.label {
		grid-column: 1 / 3;
		margin: 5px 0px 0px 5px;
	}
}

.user-profile {
	display: flex;
	align-items: center;

	&.without-icon {
		& > .right {
			width: 310px;
		}
	}
	&.full-width > .right {
		flex-grow: 1;
		width: auto;
	}

	img {
		width: 40px;
		border-radius: 20px;
	}
	p {
		margin: 0;
		padding: 0;
		text-align: left;
	}

	& > .left {
		margin-left: 10px;
	}
	& > .right {
		font-size: 1rem;
		margin-left: 10px;
		margin-top: -5px;
		width: 170px;
		.name {
			font-size: 0.9rem;
			font-weight: bold;
			color: var(--highlight);
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.email {
			font-size: 0.8rem;
			color: var(--gray);
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.update-available {
			font-size: 0.8rem;
			background: var(--highlight);
			color: var(--text);
			padding: 2.5px 5px;
			text-align: center;
			border-radius: 25px;
		}
	}
}

.small-back-btn {
	padding: 10px;
	margin-top: -10px;
	display: flex;
	align-items: center;
	i {
		font-size: 1rem;
		margin-right: 10px;
		margin-top: 2px;
	}
	&:hover {
		opacity: 0.7;
	}
}
.error-info-box {
	background: var(--lightInvalid);
	padding: 20px;
	border-radius: 4px;
	border: 2px solid var(--invalid);
	max-width: 600px;
	margin: 10px 0px;

	p,
	h1,
	h2 {
		color: #a74331;
	}
	h1 {
		font-size: 1.3rem;
	}
	h2 {
		font-size: 1.1rem;
		margin: 20px 0px 0px 0px;
	}
}

.dialog-footer .right {
	margin-right: 50px;
}

#root {
	@media (max-width: 720px) {
		--tools-input-width: calc(100% - 1px);
		.head-bar {
			left: 50px;
			width: 100vw;
			.notifications {
				display: none;
			}
			.dropdown {
				display: none;
			}
		}

		.has-sidebar {
			.center-center {
				display: block;
				margin-top: 50px;
				min-height: auto;
			}
		}
		.info-text {
			display: none;
		}

		.onedash-card {
			margin: 10px;
			width: calc(100% - 40px);
			padding: 20px;
			border-radius: 4px;

			&:first-of-type {
				margin-top: 0px;
			}
		}
		.onedash-form-component {
			margin: 0px;
			margin-top: 5px;
			width: 100%;
		}

		.onedash-button {
			margin: 10px 0px;
		}
		.onedash-dialog {
			.dialog {
				width: calc(100% - 70px);
				& > div {
					margin: 0px;
				}
				.dialog-content {
					padding-right: 0px;
					margin-bottom: 5px;
				}
			}
			.dialog-footer .right {
				margin-right: 10px;
				button {
					margin-left: 5px;
				}
			}
		}
		.onedash-table .table {
			margin: 0px;
			margin-top: 10px;
			width: 100%;
			overflow-x: hidden;
			.tbody {
				overflow-x: auto;
			}
		}
		.new-member {
			margin-left: 0px;
		}
	}
}
#root .im {
	font-family: "iconmonstr-iconic-font";
}
#root .onedash-card.style-twenty .card-content {
	padding-left: 0px;
}
.break-content {
	word-break: break-all;
}
#root .onedash-card {
	background: var(--background);
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
	padding: 15px;
	width: calc(100% - 50px);
	&.full-width {
		max-width: 100%;

		.onedash-table {
			max-width: 100%;
		}
	}
	.onedash-table-v2 {
		margin: 10px 0px;
	}
	.card-headding {
		font-weight: bold;
		color: var(--highlight);
		border: none;
		text-transform: uppercase;
		font-size: 1.3rem;
		margin-left: 0px;
		padding-left: 0px;
	}
}

a.btn {
	background: var(--highlight);
	border-color: var(--highlight);
	color: white;
	text-decoration: none;
	padding: 10px 20px;
	border-radius: 10px;
	border: 2px solid var(--highlight);
	margin-top: 20px;
	display: inline-block;
	font-size: 1rem;
	&:hover {
		background: none;
		color: var(--highlight);
	}
	&:disabled {
		pointer-events: none;
		background: var(--gray);
		border-color: var(--gray);
	}
}

/**
*			Fieldset
*/
fieldset {
	max-width: 550px;
	border: 2px solid var(--lightGray);
	margin: 10px;
	border-radius: 4px;
}

/**
*			Flexbox default styles
*/
.center-center {
	width: 100%;
	min-height: calc(100vh - 210px);
	display: flex;
	align-items: center;
	justify-content: center;
}

/**
* 			TEXT DEFAULT STYLES
*/
.text-center {
	text-align: center;
}
.bold {
	font-weight: bold;
}
.italic {
	font-style: italic;
}
.highlight {
	color: var(--highlight);
}
.underline {
	text-decoration: underline;
}
.text-right {
	text-align: right;
}
.text-left {
	text-align: left;
}

/**
*  DEFAULT PRINTING BEHAVIOR
*/

@media print {
	body,
	html {
		height: auto;
		margin-top: 0px;
		width: 100%;
		background: white !important;
		overflow-y: initial !important;
		position: relative;
		* {
			color: black;
		}
		#root {
			.dashboard {
				.dashboard-tab {
					padding: 0px !important;
				}
				.nav-bar,
				.head-bar,
				.footer-bar,
				.head-toolbar {
					display: none !important;
				}
				.onedash-card {
					background: none;
					margin: 0px !important;
					box-shadow: none;
					padding: 0px !important;
					width: 100% !important;
					max-width: 100% !important;
				}

				.main-content {
					margin: 0px !important;
					width: 100% !important;
				}
			}
		}
	}
}

@page {
	size: 210mm 297mm;
}

.toolbar {
	display: flex;
	align-items: center;
	.onedash-form-component {
		margin: 0px 5px;
		&:first-of-type {
			margin-left: 0px;
		}
		&:last-of-type {
			margin-left: 0px;
		}
		.checker {
			margin-top: 6px;
		}
	}
}

#root .onedash-dialog.new-design {
	.dialog-footer {
		.onedash-button {
			padding: 12px 20px;
			border-radius: 10px;
			font-weight: bold;
			color: var(--white);
			transition: 0.15s all ease-in-out;
			&.btn-primary {
				background: var(--highlight);
				border: 2px solid var(--highlight);
			}
			&.btn-light {
				background: var(--lightGray);
				color: var(--gray);
				pointer-events: none;
				border-color: var(--lightGray);
			}
			&.btn-secondary {
				background: var(--middleGray);
				border-color: var(--middleGray);
			}
			&:hover {
				background: none;
				color: var(--text);
			}
		}
		.right {
			margin-right: 0px;
		}
	}
}

/**
*			ONEDASH. TOOL ADJUSTMENTS
*/
.onedash-form-component {
	.onedash-select__option {
		color: var(--text);
		background-color: transparent;
		cursor: pointer;

		&:hover {
			background-color: rgba(0, 0, 0, 0.1);
		}
		&:active {
			background-color: none;
		}
	}
	.onedash-select__single-value {
		color: var(--gray);
	}
	.onedash-select__menu {
		background: var(--lightGray);
	}
}
.onedash-table.style-twenty .table::-webkit-scrollbar-track,
.onedash-table.style-twenty .table *::-webkit-scrollbar-track {
	background: none;
}
.onedash-table.style-twenty.full-width-table {
	max-width: 100%;
	.table-toolbar .left {
		flex-grow: 1;
		.onedash-input {
			max-width: 400px;
		}
	}
}
.onedash-table.style-twenty.full-height-table .tbody {
	max-height: 100%;
}
.onedash-table.style-twenty .table > div:not(.tbody) > .tr > .th {
	color: var(--tools-table-header-text);
}
.onedash-table.style-twenty .th,
.onedash-table.style-twenty .td,
.onedash-table-sorting-element.style-twenty .th,
.onedash-table-sorting-element.style-twenty .td {
	padding: 10px 20px;
}
#root .dialog-content {
	padding-right: 20px;
}
#root .spellcheck {
}
::spelling-error {
	color: red;
	text-decoration: wavy red;
}
.Toastify__toast-container {
	z-index: 99999;
}
.user-icon {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	img {
		width: 100%;
	}
	&.initials {
		background: var(--highlight);
		display: flex;
		justify-content: center;
		align-items: center;
		span {
			font-weight: 900;
			font-size: 16px;
			color: white;
		}
	}
}
#root .onedash-button {
	&.blue-btn {
		background: none;
		border-color: var(--blue);
		color: white;

		&:hover,
		&:focus {
			background: var(--blue);
		}
	}
	&.collapsed {
		margin: 5px;
		padding: 5px 10px;
	}
}
#root .onedash-dialog {
	i {
		font: normal normal normal 24px/1 "iconmonstr-iconic-font";
	}

	&.plain > .dialog-wrapper > .dialog {
		& > div {
			margin: 0px;
			& > .dialog-content {
				padding: 0px;
				margin: 0px;
				border-radius: 15px;
			}
		}
		padding: 0px;
		iframe {
			border: 0px;
		}
	}

	&.prompt-dialog {
		.dialog {
			max-width: 400px;
		}
		.dialog-footer .right {
			margin-right: 0px;
		}
	}

	&.mobile-maximize > .dialog-wrapper > .dialog {
		width: calc(100% - 10px);
		& > div > .dialog-content {
			max-height: 100vh;
		}
	}
}
