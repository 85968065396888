.nav-bar {
	padding-top: 20px;
	overflow: hidden;
	transition: 0.3s all ease-in-out;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	z-index: 5999;
	background: var(--nav-bar);
	box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.3);

	button,
	a {
		border: none;
		background: none;
		display: flex;
		align-items: center;
		padding: 28px;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		&:hover i {
			color: var(--gray);
		}

		&.active {
			span {
				height: calc(100% - 30px);
				border-radius: 0px 5px 5px 0px;
				top: 15px;
				width: 3px;
				display: block;
				background: var(--highlight);
				position: absolute;
				left: 0px;
			}
			.icon i {
				color: var(--highlight);
			}
		}
	}
	p {
		text-align: left;
		margin-left: 20px;
	}
	i {
		color: var(--highlightMiddle);
	}
}

.mobile-nav {
	.bm-burger-button {
		z-index: 50 !important;
		position: absolute;
		width: 25px;
		height: 20px;
		left: 15px;
		top: 15px;
	}

	/* Color/shape of burger icon bars */
	.bm-burger-bars {
		background: white;
	}

	/* Color/shape of burger icon bars on hover*/
	.bm-burger-bars-hover {
		background: white;
	}

	/* Position and sizing of clickable cross button */
	.bm-cross-button {
		display: none;
	}

	/* Color/shape of close button cross */
	.bm-cross {
		background: #bdc3c7;
	}

	/*
	  Sidebar wrapper styles
	  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
	  */
	.bm-menu-wrap {
		position: fixed;
		height: 100%;
	}

	/* General sidebar styles */
	.bm-menu {
		background: #373a47;
		padding: 20px 10px;
		font-size: 1.15em;
	}

	/* Morph shape necessary with bubble or elastic */
	.bm-morph-shape {
		fill: #373a47;
	}

	/* Wrapper for item list */
	.bm-item-list {
		color: #b8b7ad;
		padding: 0px;
		height: calc(100% - 20px) !important;
	}

	/* Individual item */
	.bm-item {
		display: inline-block;
	}

	/* Styling of overlay */
	.bm-overlay {
		background: rgba(0, 0, 0, 0.3);
	}

	.profile-dropdown {
		width: 260px;
		.dropdown-content {
			margin-top: 10px;
		}
		.left {
			margin-left: 0px;
		}
		.email {
			color: white !important;
		}
	}

	.main-nav {
		display: flex !important;
		flex-direction: column;
		justify-content: space-between;
		height: calc(100% - 30px);
		padding: 0px 10px;

		h1 {
			font-size: 0.9rem;
			color: white;
			margin-top: 20px;
		}
		.nav-item {
			padding: 5px 0px;
			margin: 5px 0px;

			.module-header {
				padding: 5px 10px;
				display: flex;
				align-items: center;

				.name {
					color: var(--highlightMiddle);
					font-weight: bold;
					font-size: 1.1rem;
				}

				i {
					margin-right: 10px;
					font-size: 20px;
					color: var(--highlightMiddle);
				}

				&.active .name,
				&.active i {
					color: var(--highlight);
				}
			}

			.tabs {
				.tab.active {
					color: var(--highlight);
				}
				ul {
					margin: 0px;
					padding: 0px;
					margin-left: 0px;
					li {
						list-style: none;
						a {
							padding: 7.5px 20px 7.5px 30px;
							width: calc(100% - 40px);
							display: block;
						}
					}
				}
			}
		}

		a,
		.name {
			text-decoration: none;
			color: white;
			font-size: 1rem;
			line-height: 100%;
		}
	}
}
