.head-bar {
	display: flex;
	z-index: 6000;
	background: var(--head-bar);
	align-items: center;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

	.app-dropdown {
		margin: 0px 10px;

		& > .disabled {
			pointer-events: none;
		}
		.icon {
			background: var(--highlight);
			width: 40px;
			height: 40px;
			border-radius: 4px;
			display: flex;
			justify-content: center;
			align-items: center;
			span {
				font-size: 18px;
				font-weight: 900;
				color: white;
				text-align: center;
			}
		}
		.dropdown-content {
			ul {
				padding: 0px;
				li {
					list-style: none;

					margin: 7.5px 0px;
					button {
						align-items: center;
						display: flex;
						text-decoration: none;
						transition: 0.15s all ease-in-out;
						&:hover {
							opacity: 0.7;
						}
					}
					p {
						font-weight: normal;
						margin: 0px;
						margin-left: 10px;
						font-size: 0.9rem;
					}
					.icon {
						width: 30px;
						height: 30px;
						span {
							font-size: 14px;
						}
					}
				}
			}
		}
	}
	.app-switch {
		width: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: 0.15s all ease-in-out;

		&:hover {
			opacity: 0.7;
		}

		.app-switch-button {
			margin-left: 5px;
			i {
				color: var(--highlight);
				font-size: 15px;
				transition: 0.15s all ease-in-out;
				padding-top: 5px;
			}
			&.show i {
				transform: rotate(180deg);
			}
		}
	}

	& > .tabs {
		flex-shrink: 1;
		flex-grow: 1;
		overflow-x: auto;
		height: 100%;
		&::-webkit-scrollbar {
			height: 0px;
		}
		ul {
			display: flex;
			align-items: center;
			margin: 0px;
			height: 100%;
			padding-left: 0px;
			li {
				list-style: none;

				height: 100%;
				position: relative;
			}
			a {
				text-decoration: none;
				font-weight: bold;
				font-size: 1rem;
				position: relative;
				display: flex;
				align-items: center;
				height: 100%;
				padding: 0px 20px;

				p {
					color: var(--middleGray);
					margin: 0px;
					text-align: center;
				}
				&:hover {
					p {
						color: var(--gray);
					}
				}
				&.active {
					p {
						color: var(--gray);
					}
					span {
						display: block;
						position: absolute;
						width: 70px;
						top: 0px;
						height: 3px;
						background: var(--highlight);
						left: calc(50% - 35px);
						border-radius: 0px 0px 5px 5px;
					}
				}
			}
		}
	}

	& > .side {
		display: flex;
		align-items: center;
		& > button {
			height: 100%;
			background: none;
			border: none;
			border-radius: 0px;

			&.notifications {
				width: 70px;
			}
			i {
				color: var(--middleGray);
			}
			&:hover i {
				color: var(--gray);
			}
		}
		& > span {
			margin-left: 1px;
			background: var(--lightGray);
			display: block;
			width: 1px;
			height: calc(100% - 30px);
		}
	}
}
.mobile-head-bar {
	height: 50px;
	background: var(--highlight);
	display: flex;
	align-items: center;
	justify-content: center;

	.app-switch {
		display: flex;
		align-items: center;
		h2 {
			font-size: 1.2rem;
			color: white !important;
		}
		.app-switch-button i {
			margin-left: 10px;
			margin-top: 3px;
			font-size: 1rem;
			color: white !important;
		}
	}
	.dropdown-content {
		margin-top: 10px !important;
		ul {
			padding: 0px;
			margin: 0px;
			li {
				list-style: none;
				margin: 5px 0px;
				button {
					display: flex;
					align-items: center;
				}
				p {
					margin: 0px;
				}
				.icon {
					width: 25px;
					height: 25px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 10px;
					span {
						font-weight: bold;
					}
				}
			}
		}
	}
}
