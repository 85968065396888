.login-wrapper {
	--loginHighlight: #fd7e14;

	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	.login-carousel {
		width: 100%;
		display: flex;
		justify-content: center;
		& > div {
			width: 100%;
			max-width: 1100px;
			margin: -20px 20px 0px 20px;
			height: 550px;
		}
	}

	.input-step {
		background: var(--white);
		box-shadow: var(--boxShadow);
		height: 100%;
		&.theme-selection {
			padding: 30px 50px;
			position: relative;

			.split {
				display: flex;
				height: 100%;
				align-items: center;

				.left {
					height: 100%;
					margin: -30px 20px -30px -50px;
					padding: 30px 50px;
					flex-basis: 350px;
					background: #342b2c;
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					h2 {
						color: white;
						margin-top: 100px;
					}
					h3 {
						color: rgba(255, 255, 255, 0.582);

						font-size: 1.1rem;
						margin-top: -15px;
						font-weight: normal;
					}
					p {
						color: var(--middleGray);
						font-weight: normal;

						font-size: 0.8rem;
					}
					.onedash-logo {
						span:nth-child(1) {
							color: white;
						}
					}

					.onedash-button.style-twenty.btn-primary {
						background: var(--loginHighlight);
						border-color: var(--loginHighlight);
						color: black;
						&:hover {
							background: none;
							color: white;
						}
					}
				}
			}

			.schemas {
				display: flex;
				position: relative;

				.schema {
					margin: 0px 15px;
					border: 2px solid var(--lightGray);
					padding: 0px 30px 30px 30px;
					border-radius: 4px;
					flex-basis: 250px;
					flex-grow: 1;
					cursor: pointer;

					&.selected {
						border-color: var(--loginHighlight);
					}

					&:hover {
						border-color: var(--lightBlue);
					}

					&:last-of-type {
						margin-right: 0px;
					}
					img {
						width: 100%;
						box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
					}

					p {
						text-align: center;
					}
				}
			}
		}
		&.pw-change {
			display: flex;
			justify-content: space-between;
			@media only screen and (max-width: 900px) {
				& > .left {
					display: none;
				}
			}
			& > .left {
				flex-grow: 1;
				background-size: cover;
				background-position: center;
				background-image: url(./pw-change-img.jpg);
				position: relative;
			}
			& > .right {
				margin-top: 80px;
				display: flex;
				padding: 30px 50px;

				h1 {
					margin-top: -50px;
				}
				h2 {
					font-size: 1.7rem;
					margin-top: 50px;
				}
				h3 {
					font-weight: normal;
					margin-top: -10px;
				}

				& > .left {
					width: 270px;
					.disabled {
						border-color: var(--lightGray) !important;
						background: var(--lightGray) !important;
					}
				}
				& > .right {
					h2 {
						font-size: 1.2rem;
						font-weight: bold;
						margin-top: 0px;
					}
					margin-top: 120px;
					margin-left: 30px;
					ul {
						padding: 0px;
						li {
							list-style: none;
							margin: 5px 0px;
							padding: 0px;
							&.valid {
								text-decoration: line-through;
								opacity: 0.6;
							}
						}
					}
				}
			}
		}
	}

	button.onedash-button.btn-primary {
		margin: 0px;
		width: 200px;
		border-radius: 10px;
		margin-top: 30px;
	}
	.btn-link.onedash-button {
		margin: 0px;
		padding-left: 0px;
		padding-right: 0px;
	}

	.login {
		background: var(--white);
		box-shadow: var(--boxShadow);
		height: 100%;
		display: flex;
		position: relative;

		&.waiting .right {
			width: 0;
			min-width: 0;
			padding: var(--padding) 0px;
		}

		& > div {
			transition: 0.15s all ease-in-out;
		}

		h2 {
			margin-top: 50px;
			font-size: 1.5rem;
		}
		h3 {
			font-weight: normal;
			font-size: 1.1rem;
			margin-top: -10px;
		}

		.right {
			padding: 30px 80px;
			width: 100%;
			min-width: 230px;
			max-width: 280px;
			flex-shrink: 0;
			overflow: hidden;

			.flex {
				display: flex;
				justify-content: space-between;
				.onedash-boolean {
					display: flex;
					align-items: center;
					.onedash-children {
						cursor: pointer;
						margin-left: 5px;
					}
				}
			}
			.btn-primary {
				margin: 50px 0px 0px 0px;
				width: 200px;
				border-radius: 10px;
			}
		}
		.left {
			flex-grow: 1;
			background-size: cover;
			background-position: center;
			background-image: url(./login-img.jpg);
			position: relative;

			p {
				color: white;
				text-align: center;
				position: absolute;
				bottom: 30px;
				width: 100%;
			}

			.loader {
				position: absolute;
				bottom: 0px;
				width: 100%;

				span {
					width: 100px;
					transition: 0.4s width linear;
					display: block;
					height: 10px;
					background: var(--loginHighlight);
					box-shadow: 0px 0px 50px var(--loginHighlight);
				}
			}
		}

		@media only screen and (max-width: 900px) {
			&.waiting .left {
				width: 100%;
			}
			.left {
				transition: none;
				width: 0px;
				flex-shrink: 0;
			}
			.right {
				transition: none;

				width: 100%;
				flex-grow: 1;
				max-width: calc(100% - 160px);
			}
		}
		@media only screen and (max-width: 720px) {
			height: auto;
			margin-top: -100px;
			.dark-light-switch {
				right: 30px;
				transform: scale(0.6);
				top: 38px;
			}
			.right {
				max-width: calc(100% - 100px);
				padding: 30px 50px;
			}
			.btn-primary {
				width: 100% !important;
				margin-bottom: 10px !important;
			}
		}
	}
	.onedash-form {
		margin-top: 70px;
		.onedash-input {
			width: 100%;
			border: 1px solid var(--lightGray);
			border-radius: 10px;
			position: relative;
			margin: 20px 0px;
			transition: 0.15s all ease-in-out;
			label {
				font-size: 0.8rem;
				display: block;
				text-transform: uppercase;
				position: absolute;
				left: 22.5px;
				top: -7px;
				color: var(--gray);
				padding: 0px 7.5px;
				background: var(--white);
				.required {
					display: none;
				}
			}

			input {
				padding: 14px 30px 10px 30px;
				width: calc(100% - 60px);
				border: none;
				font-size: 1rem;
				background: none;
				&:focus {
					outline: none;
				}
			}
			&.focused {
				border-color: var(--lightBlue);
			}
			&.input-invalid {
				border-color: var(--invalid);
			}
		}
		.checkmark {
			display: none;
		}
	}
}
