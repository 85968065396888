.application-logger-dialog
    .json, .hljs, .hljs-subst, .hljs-attr
        color: #f8f8f2
    .hljs-number
        color: var(--highlight)

    .log-entry
        margin-top: 20px
        display: grid
        grid-template-columns: 100px 1fr
        grid-gap: 10px
        .label
            font-weight: bold

    .subtasks
        .subtask
            background: var(--lightGray)
            margin: 5px 0px
            display: grid
            grid-gap: 10px 0px

            grid-template-columns: 1fr 1fr
            padding: 5px
            border-radius: 4px
            .description
                grid-column: 1 / 3
                display: block
                font-style: italic
