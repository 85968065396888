.profile-dropdown {
	padding-right: 15px;
	width: 275px;

	.icon {
		margin-left: 20px;

		i {
			transition: 0.15s all ease-in-out;
			color: var(--lightGray);
			font-size: 13px;
		}
		&.shown i {
			transform: rotate(180deg);
		}
	}
	ul {
		margin: 0px;
		padding: 0px;
		li {
			display: flex;
			list-style: none;
			justify-content: space-between;
			border-top: 1px solid var(--lightGray);
			align-items: center;

			&:first-child {
				border-top: none;
			}
			.dark-light-switch {
				transform: scale(0.7);
			}

			p,
			a,
			button {
				padding: 10px 0px 10px 10px;
				margin: 0px;
				font-size: 1rem;
				width: 100%;
				text-align: left;
				text-decoration: none;
				font-weight: normal;
			}
			button:hover,
			a:hover {
				transition: 0.15s all ease-in-out;
				opacity: 0.7;
			}
		}
	}

	.user-profile {
		display: flex;
		align-items: center;

		img {
			width: 40px;
			border-radius: 20px;
		}
		p {
			margin: 0;
			padding: 0;
			text-align: left;
		}

		& > .left {
			margin-left: 10px;
		}
		& > .right {
			font-size: 1rem;
			margin-left: 10px;
			margin-top: -5px;
			width: 170px;
			.name {
				font-size: 0.9rem;
				font-weight: bold;
				color: var(--highlight);
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.email {
				font-size: 0.8rem;
				color: var(--gray);
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.update-available {
				font-size: 0.8rem;
				background: var(--highlight);
				color: var(--text);
				padding: 2.5px 5px;
				text-align: center;
				border-radius: 25px;
			}
		}
	}
}
.about-text {
	h1 {
		color: var(--highlight);
		font-size: 1.7rem;
		margin-top: 25px;
		margin-bottom: 0px;
		font-weight: 900;
		text-transform: uppercase;
	}
	h2 {
		color: var(--onedashGray);
		font-size: 1.5rem;
		margin-top: 25px;
		margin-bottom: 0px;
		font-weight: 900;
		text-transform: uppercase;
	}
	h3 {
		color: var(--onedashGray);
		font-size: 1.3rem;
		margin: 10px 0 0 0;
	}
	p {
		margin: 5px;
		font-weight: normal;
		line-height: 1.5rem;
	}
	a {
		color: var(--highlight);
		text-decoration: none;
		&:hover {
			opacity: 0.8;
		}
	}

	table {
		margin-top: 20px;
		width: 100%;
		font-size: 1rem;
		th {
			text-align: left;
			font-weight: bold;
		}
		td {
			padding: 10px 0px;
		}
	}

	.footer {
		text-align: center;
		margin-top: 50px;
		p {
			margin: 2.5px 0px;
			opacity: 0.8;
			font-size: 0.9rem;
		}
	}
}

.profile {
	position: relative;

	& > div {
		margin: 60px 0px 0px 10px;

		&:first-of-type {
			margin-top: 20px;
		}
	}
	a {
		color: var(--lightBlue);
		text-decoration: none;
	}
	h1 {
		text-transform: uppercase;
		font-weight: 900;
		color: var(--highlight);
		font-size: 1.7rem;
	}
	h2 {
		margin: 0px;
		color: var(--middleGray);
		font-size: 1.1rem;
		border-bottom: 1px solid var(--lightGray);
	}
	.colors {
		display: flex;
		.onedash-button.style-twenty.btn-primary {
			margin: 10px 5px 5px 5px !important;
		}
		button span {
			display: block;
			width: 15px;
			height: 15px;
			border-radius: 25%;
			position: relative;

			&.selected::after {
				content: "";
				position: absolute;
				width: 5px;
				height: 5px;
				background-color: white;
				border-radius: 25%;
				top: 50%;
				left: 50%;
				transform: translateX(-50%) translateY(-50%);
			}
		}
	}
	.split {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		& > div:nth-child(1) {
			& > div:first-of-type {
				.title {
					margin-top: 0px;
				}
			}
		}

		.profile-image {
			margin-left: 30px;
			flex-basis: 120px;
			margin-top: 20px;
			img {
				border-radius: 50%;
				width: 100%;
			}
		}
	}
	.title {
		font-weight: 800;
		text-transform: uppercase;
		font-size: 1rem;
		margin: 20px 0px 0px 0px;
	}
	.value {
		display: block;
		margin: 10px 5px;
	}
	a {
		transition: 0.15s all ease-in-out;
		font-weight: normal;
		&:hover {
			opacity: 0.7;
		}
	}

	.onedash-button.style-twenty.btn-primary {
		margin: 10px 5px;
		padding: 0px;
		background: none;
		color: var(--lightBlue);
		border: none;

		&:hover {
			opacity: 0.7;
		}
	}
}
