#root h1.onedash-logo
	font-size: 2rem
	& > span
		&:nth-child(1)
			color: white

			&:nth-child(2)
				color: var(--onedashOrange)

			&:nth-child(3)
				color: var(--onedashGreen)
