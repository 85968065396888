
#root .list-detail.onedash-card
	padding-right: 0px
#root .list-detail .card-content
	display: flex
	height: calc(100vh - 140px)

	.left-list
		flex: 1
		overflow-y: auto
		position: relative

		.add-icon
			margin: 0px
			position: absolute
			right: 10px
			top: 20px
			i
				font-size: 20px
				color: var(--highlight)
				transition: 0.15s all ease-in-out
				&:hover
					opacity: 0.9
					transform: rotate(90deg)

	.left-list, .detail-view .content
		overflow-y: scroll
		overflow-x: hidden

		&::-webkit-scrollbar
			width: 8px
			height: 8px

		&::-webkit-scrollbar-button
			width: 8px
			height: 8px

		&::-webkit-scrollbar-thumb
			background: var(--scrollbar-thumb)
			border: 0px var(--background)
			border-radius: 23px

		&::-webkit-scrollbar-thumb:hover
			opacity: 0.7

		&::-webkit-scrollbar-corner
			background: transparent

	.detail-view
		flex-basis: 450px
		max-width: 450px
		box-shadow: -5px 0px 10px -5px rgba(0,0,0,0.3)
		margin: -15px 0px
		margin-left: 15px

		.onedash-table .table
			margin: 0px
			width: 100%

		& > .content
			padding: 20px 30px
			height: calc(100% - 79px)

		.toolbar
			padding: 0px 30px
			background: var(--nav-bar)

			h2
				font-size: 1.1rem
				color: var(--highlight)
				text-transform: uppercase
				margin: 10px 0px
				flex: 1
				white-space: nowrap
				overflow: hidden
				text-overflow: ellipsis

			.split
				display: flex
				justify-content: space-between
				width: calc(100% + 20px)

		.pane
			border-bottom: 1px solid var(--lightGray)
			padding: 20px 0px 20px 0px
			&:last-of-type
				border-bottom: none

			&.collapsible
				.header
					display: flex
					align-items: center
					padding: 10px 10px 15px 10px
					margin: 0px -10px
					justify-content: space-between
					cursor: pointer
					& > div:first-of-type
						display: flex
						align-items: center
					h3
						margin: 0px
						margin-left: 10px
					.im-angle-down
						font-size: 15px
						transition: 0.15s all linear
				&.collapsed
					.im-angle-down
						transform: rotate(180deg)
					.content
						max-height: 0

				.content

					overflow: hidden
					max-height: 400px
					transition: 0.3s all linear
			.split
				display: flex
				margin: -20px -20px 0px -20px

				& > div
					margin: 10px 20px
					.property
						display: block
					.value
						display: block
						text-align: left
					.onedash-form-component
						margin: 0px
						width: 100%
						.onedash-select, textarea
							margin-top: 0px

					.label
						font-size: 0.9rem

			&.summary
				padding-top: 10px
			&.header

				h2
					margin: 10px 0px 50px 0px
				.header-childs
					display: flex
					margin: 0px -20px
					.split
						margin: 0px 20px
						display: flex
						align-items: center

						.person, .icon-wrapper
							margin: 0px

						& > div:last-of-type
							margin: 0px 0px 0px 10px
							.label
								color: var(--middleGray)
								font-size: 0.9rem

							.value
								font-weight: bold
						.icon-wrapper i
							color: var(--highlight)

			h3
				display: flex
				align-items: center
				font-size: 15px
				margin: 5px 0px 20px 0px
				i
					margin-right: 10px
					font-size: 18px

			.property
				display: flex
				align-items: center
				margin: 10px 0px
				.label
					flex-basis: 100px
					color: var(--middleGray)
					margin-right: 10px
				.value
					flex: 1
					display: flex
					justify-content: flex-end
					text-align: right

					.onedash-boolean
						width: auto
						z-index: 0
						.checker
							margin: 0px

					.onedash-form-component
						margin: 0px
						.onedash-select, textarea
							margin-top: 0px
							text-align: left
					a
						text-decoration: none
						color: var(--highlight)
