.mini-page-wrapper
	width: 100vw
	height: 100vh
	display: grid
	grid-template-rows: auto 50px
	grid-template-columns: 1fr
	justify-content: center
	align-items: center

	--brown-1: #342b2c
	--brown-2: #181818
	--brown-2-trans: #181818e8
	--brown-3: #4d4041
	--gray: #9c9797

	--orange: #fd7e14
	--orange-red: #ca640f

	.orange
		color: var(--orange)
	.footer
		margin-left: auto
		margin-right: auto
		button, a
			font-size: 12px
			text-decoration: none
			font-weight: normal
	.mini-page
		width: 100%
		max-width: 1100px
		margin: -20px auto 0px auto
		height: 550px
		background: var(--brown-1)
		box-shadow: var(--boxShadow)
		display: grid

		.content
			margin: 0px
			padding: 0px
			flex-grow: 1
			position: relative
			& > div
				height: 100%
				position: relative

		.header
			width: 100%
			display: grid
			background: var(--brown-2)

			.page
				display: flex
				justify-content: center
				padding: 10px 0px
				&.active
					background: var(--orange)
				span
					color: white
				.label
					margin-left: 5px

	.company-information
		.onedash-form
			height: 100%
			& > div:first-of-type
				height: 100%
			.entry-form
				height: 100%

		.onedash-form-v2-component.disabled
			.input-wrapper
				opacity: 0.6

		h2
			font-size: 1.5rem
			margin-top: 30px
		.img
			background: url("./Images/profile.png")
			background-color: var(--brown-3)
			background-size: 70%
			background-repeat: no-repeat
			background-position: center

		.entry-form
			display: grid
			grid-template-columns: 1fr 1fr 1fr
			gap: 20px

			.transfer-code
				border-top: 1px solid gray
				padding: 10px

			& > div
				padding: 0px 30px
				&:last-child
					display: grid
					grid-template-rows: auto 120px 75px
					padding: 0px
					background-color: var(--brown-3)
					& > div:last-child
						border-top: 1px solid gray
						padding: 10px
						display: flex
						align-items: center
						justify-content: center

		.street-house-number
			display: grid
			grid-template-columns: auto 100px
			gap: 20px

	.loading-page
		.img
			background: url("./Images/loading.png")
			background-color: var(--brown-1)
			background-size: 50%
			background-repeat: no-repeat
			background-position: center
			height: 100%
		p
			color: white
			text-align: center
			position: absolute
			bottom: 15px
			width: 100%

		.loader
			position: absolute
			bottom: 0px
			width: 100%

			span
				width: 100px
				transition: 0.4s width linear
				display: block
				height: 10px
				background: var(--orange)
				box-shadow: 0px 0px 50px var(--orange)

	.gaestebeitrag-response
		position: relative
		display: grid
		grid-template-columns: auto 360px
		.left
			background: url("./Images/done.png")
			background-color: var(--brown-1)
			background-size: 70%
			background-repeat: no-repeat
			background-position: center
		.right
			background: var(--brown-2)
			display: grid
			grid-template-rows: auto 1fr
			padding: 30px

	.gaestebeitrag-landing-page
		position: relative
		display: grid
		grid-template-columns: auto 360px

		.right
			background: var(--brown-2)
			display: grid
			grid-template-rows: auto 1fr
			padding: 30px
		.left
			background: url("./Images/chart-data.png")
			background-color: var(--brown-1)
			background-size: 70%
			background-repeat: no-repeat
			background-position: center
		.vg-logo
			width: 50px
			position: absolute
			top: 20px
			left: 20px

	.email-validation
		.right
			margin-top: 30px

		.left
			background: url("./Images/email.png")
			background-color: var(--brown-3)
			background-size: 70%
			background-repeat: no-repeat
			background-position: center
		.bold
			color: white

	.company-stats
		display: grid
		grid-template-columns: 2fr 1fr

		& > div
			display: grid
			grid-template-rows: auto 75px
			padding: 0px
			background-color: var(--brown-3)
			&>div:last-of-type
				border-top: 1px solid gray
				padding: 10px
				display: flex
				align-items: center
				justify-content: center

		.inputs
			display: grid
			grid-template-columns: 1fr 1fr
			gap: 30px
			padding: 30px
			h2
				color: var(--orange)
				font-size: 1.3rem
			p
				color: white
				line-height: 130%

		.img
			background: url("./Images/apartment.png")
			background-size: 90%
			background-repeat: no-repeat
			background-position: center
	.password-change
		display: grid
		grid-template-columns: 1fr 1fr

		& > .left
			flex-grow: 1
			background-size: cover
			background-position: center
			background-image: url(./Images/pw-change-img.jpg)
			position: relative

		& > .right
			display: grid
			grid-template-columns: auto 200px
			gap: 40px
			padding: 30px 50px

			& > .left
				width: 270px
				& > h2
					margin-top: 40px
				.disabled
					border-color: var(--lightGray) !important
					background: var(--lightGray) !important

			& > .right
				h2
					font-size: 1.2rem
					font-weight: bold
					margin-top: 170px
					color: var(--orange)

				ul
					margin-top: 0px
					padding: 0px
					li
						list-style: none
						margin: 5px 0px
						padding: 0px
						color: white
						&.valid
							text-decoration: line-through
							opacity: 0.6

	.theme-selection
		position: relative

		.split
			display: grid
			grid-template-columns: 300px auto
			height: 100%

			& > div
				padding: 30px

			.left
				display: flex
				flex-direction: column
				justify-content: center
				background: var(--background)
				h2
					color: var(--highlight)
					transition: none
				h3
					color: var(--text)

				p
					color: var(--gray)
					font-weight: normal
					font-size: 0.8rem

				.onedash-button.style-twenty.btn-primary
					background: var(--loginHighlight)
					border-color: var(--loginHighlight)
					color: black
					&:hover
						background: none
						color: white
		.right
			h2
				margin: 30px 0px 20px 15px
				font-size: 1.3rem
			.colors
				margin-left: 15px
				margin-top: -5px
		.schemas
			display: grid
			position: relative
			grid-template-columns: 1fr 1fr
			.schema

				border-radius: 4px
				cursor: pointer

				&.selected img
					box-shadow: 0px 0px 20px var(--highlight)

				&:hover
					border-color: var(--lightBlue)

				&:last-of-type
					margin-right: 0px

				img
					width: 100%
					max-width: 300px
					box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2)

				p
					text-align: center
					&.bold
						font-weight: bold
						color: white

		.colors
			display: flex

			button span
				display: block
				width: 15px
				height: 15px
				border-radius: 25%
				position: relative

				&.selected::after
					content: ""
					position: absolute
					width: 5px
					height: 5px
					background-color: white
					border-radius: 25%
					top: 50%
					left: 50%
					transform: translateX(-50%) translateY(-50%)

	.split-input-50
		display: grid
		grid-template-columns: 1fr 1fr
		gap: 20px
	.split-v-2-1
		display: grid
		grid-template-columns: 2fr 1fr

	.split-v-3
		display: grid
		grid-template-columns: 1fr 1fr 1fr

		gap: 20px

	.split-v-60-40
		display: grid
		grid-template-columns: 6fr 4fr

	.split-v-50
		display: grid
		grid-template-columns: 1fr 1fr

	.split-v-2-1 > div,.split-v-50 > div, .split-v-60-40 > div, .split-v-3 > div
		padding: 30px

	.split-h-50
		display: grid
		grid-template-rows: 1fr 1fr

	h2
		font-size: 2rem
		color: white
		margin: 0px

	h3
		color: rgba(255, 255, 255, 0.8)
		font-size: 1.2rem
		font-weight: normal
		margin: 0px

	p
		color: var(--middleGray)
		font-weight: normal
		margin: 10px 0px
		font-size: 1rem

		&.warning
			color: var(--orange)
		button
			color: white
			font-weight: bold
			padding: 0px

	.company-exists-contact
		align-items: center
		display: flex

		& > div
			&:first-of-type
				margin: 40px
				flex-basis: 300px
				flex-grow: 0
			&:last-of-type
				flex: 1
				display: flex
				justify-content: center
		.bold
			color: var(--middleGray)
		img
			width: 100%
			max-width: 500px
			display: inline-block
			@media screen and ( max-width: 720px )
				display: none

	.company-already-exists
		max-width: 1000px
		margin-left: auto
		margin-right: auto
		margin: 40px
		.selection
			display: flex
			margin-top: 40px
			flex-wrap: wrap
			gap: 20px
			justify-content: center
		button
			flex: 1
			flex-basis: 400px
			max-width: 500px

			img
				width: 60%
			h3
				margin-top: 15px
			&:hover
				opacity: 0.7

	.btn
		background: var(--orange)
		border-color: var(--orange)
		color: black
		text-decoration: none
		padding: 10px 20px
		border-radius: 10px
		border: 2px solid var(--orange)
		margin-top: 20px
		display: inline-block
		font-size: 1rem
		width: 100%
		&:hover
			background: none
			color: white

		&:disabled
			pointer-events: none
			background: var(--gray)
			border-color: var(--gray)

		&.btn-2
			background: var(--orange-red)
			border-color: var(--orange-red)
			&:hover
				background: none

	@media (max-width: 1130px)
		grid-template-rows: auto 60px
		.footer
			padding: 20px 0px
		.content
			height: 100%
		.mini-page
			margin: 20px
			width: calc(100% - 40px)
			height: calc(100% - 40px)

		.gaestebeitrag-landing-page
			display: flex
			justify-content: center
			align-items: center
			background: url(./Images/chart-data.png)
			background-size: 90%
			background-repeat: no-repeat
			background-position: center
			height: 100%
			.left
				display: none
			.vg-logo
				right: 30px
				top: 30px
				left: auto
			.right
				max-width: 300px
				margin-left: auto
				margin-right: auto
				margin: 0px
				display: block
				position: relative
				background: var(--brown-2-trans)
				backdrop-filter: blur(8px)
				h2
					margin-top: 30px
			.split-h-50
				display: block

		.email-validation
			grid-template-columns: auto
			align-items: center
			background: url("./Images/email.png")
			background-color: var(--brown-3)
			background-size: 90%
			background-repeat: no-repeat
			background-position: center

			.left
				display: none
			.right
				max-width: 300px
				margin-left: auto
				margin-right: auto
				background: var(--brown-2-trans)
				backdrop-filter: blur(8px)
				margin-top: 0px
				padding-top: 50px
				height: auto

		.company-stats
			display: flex
			flex-direction: column
			align-items: center
			justify-content: center

			background: url("./Images/apartment.png")
			background-color: var(--brown-3)
			background-size: 90%
			background-repeat: no-repeat
			background-position: center

			h2
				margin-top: 20px

			.inputs
				max-width: 300px
				width: calc(100% - 100px)
				margin: 20px
				display: flex
				flex-direction: column
				background-color: var(--brown-2-trans) !important
				backdrop-filter: blur(8px)

				gap: 0px

			&>div
				background: none
				padding: 20px 0px
				margin-top: -20px
				&>div:last-of-type
					border-top: none

		.password-change
			display: flex
			justify-content: center
			align-items: center
			background: url("./Images/pw-change-img.jpg")
			background-color: var(--brown-1)
			background-size: 100%
			background-repeat: no-repeat
			background-position: center

			& > .left
				display: none
			& > .right
				background-color: var(--brown-2-trans) !important
				backdrop-filter: blur(8px)
		.theme-selection
			.schemas
				grid-template-rows: 1fr 1fr
				grid-template-columns: 1fr
				gap: 20px
			img
				max-width: 250px !important
			.colors
				button span
					width: 25px
					height: 25px
					&::after
						width: 10px !important
						height: 10px !important
		.company-information
			.entry-form
				display: flex
				flex-direction: column
				align-items: center
				justify-content: center
				padding: 0px
				background: url("./Images/profile.png")
				background-color: var(--brown-3)
				background-size: 70%
				background-repeat: no-repeat
				background-position: center

				gap: 0px

				h2
					margin: 0px
				.mailing-address
					margin-top: 20px
				.onedash-form-component
					margin-top: 10px !important

				& > div
					background-color: var(--brown-2-trans) !important
					backdrop-filter: blur(8px)
					max-width: 300px
					width: 100%
					padding: 30px !important
					&:last-of-type
						display: block
						& > div:last-child
							border-top: none

				.img
					display: none

	@media (max-width: 720px)
		.mini-page
			margin: 0px
			width: 100%
			height: 100%
			display: flex
			flex-direction: column
			.header
				display: none

			.loader
				bottom: auto
				top: 0px
			.loading-page p
				bottom: auto
				top: 20px

			.theme-selection
				.split
					display: flex
					flex-direction: column-reverse

			.password-change
				& > .right
					display: flex
					flex-direction: column
					.right h2
						margin-top: 0px

@media (max-width: 720px)
	.minipage-dialog .dialog
		margin-top: -100px
