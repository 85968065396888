.button-spinner
	margin: 0px auto

	text-align: center
	& > div
		width: 12px
		height: 12px
		background-color: white

		border-radius: 100%
		display: inline-block
		animation: sk-bouncedelay 1.4s infinite ease-in-out both
	.bounce1
		animation-delay: -0.32s
	.bounce2
		animation-delay: -0.16s

	@keyframes sk-bouncedelay
		0%, 80%, 100%
			transform: scale(0)
		40%
			transform: scale(1.0)
