.info-box
	display: flex
	align-items: center
	border-radius: 8px
	padding: 15px 20px
	margin: 20px 0px
	line-height: 1.2rem
	transition: 0.3s all linear
	overflow: hidden
	border: 2px solid
	font-weight: bold
	font-size: 0.9rem

	$success: #33FFB3
	$success-light: #33ffb48e
	$info: #2c74f1
	$info-light: #337eff50
	$warning: #FFE133
	$warning-light: #FFE1338e
	$error: #FF5733
	$error-light: #FF57338e

	a
		font-weight: bold
		text-decoration: underline
	i
		margin-right: 20px
		font-size: 1.4rem
	p
		margin: 20px
		font-weight: bold
	&.hidden
		max-height: 0px
		padding: 0px 10px !important
		border: none !important
	&.small
		padding: 10px
		margin: 5px 0px
		p
			margin: 0px
			font-weight: normal

		i
			margin-right: 10px

	&__info
		background: $info-light
		border-color: $info
		a,i,p,.content
			color: $info
	&__warning
		background: $warning-light
		a,i,p,.content
			color: $warning
	&__error
		background: $error-light
		a,i,p,.content
			color: $error
	&__success
		background: $success-light
		a,i,p,.content
			color: $success

.user-selection-dialog
	.split
		display: flex
		justify-content: space-between
		align-items: center
	.dialog,
	.onedash-form-v2-component
		max-width: 400px !important
	.content
		padding-right: 0px !important
	.user-profile
		margin: 10px 0px
		border-bottom: 1px solid var(--lightGray)
		padding-bottom: 10px
		transition: 0.15s all ease-in-out
		&:not(.full-width):hover
			opacity: 0.7
		&:last-of-type
			padding-bottom: 0px
			border-bottom: 0px
			margin-bottom: 0px
		&:first-of-type
			margin-top: 0px
	.selected-users-list
		max-height: 200px
		overflow-y: auto
		margin: 20px 0px
	.remove-user-btn
		&:hover
			opacity: 0.7
		i
			font-size: 15px
	.user-recommendation
		position: absolute
		width: 380px
		background: var(--background)
		max-height: 200px
		overflow-y: auto
		padding: 10px
		border-radius: 10px
		border: 1px solid var(--middleGray)
		box-shadow: 2px 2px 5px rgba(0,0,0,0.15)
		h3,h4
			margin: 0px
