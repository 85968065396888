.onedash-form-v2-component
	position: relative
	box-sizing: border-box
	max-width: 300px
	width: 100%
	margin: 10px 0px
	display: inline-block
	*
		box-sizing: border-box
	.onedash-label
		display: block
		font-size: 11px
		color: var(--middleGray)
		margin: 0px 0px 5px 5px

		.required
			margin-left: 3px
	&.full-width
		max-width: 100%
	&.with-icon
		.placeholder-required
			right: 64px
		input, select, textarea
			padding-right: 75px
		.onedash-custom-select__control
			padding-right: 60px
		&.onedash-boolean .checker
			width: 118px

	.placeholder-required
		position: absolute
		color: var(--invalid)
		right: 10px
		top: 10px

	.input-wrapper
		position: relative

	&.disabled .input-icon
		opacity: 0.4
	.input-icon
		position: absolute
		background: var(--tools-icon-background)
		height: calc(100% - 2px)
		width: 54px
		right: 1px
		top: 1px
		border-radius: 0px 10px 10px 0px
		display: flex
		justify-content: center
		align-items: center
		z-index: 1
		i
			color: var(-middle-gray)
			font-size: 20px

	&.onedash-boolean.disabled
		.onedash-children
			pointer-events: none
		.checker
			pointer-events: none
			.check-bg::after
				background-color: var(--veryLightGray) !important
		.checkmark svg
			background: var(--middleGray) !important
	input, select, textarea
		padding: 12px 10px
		border-radius: 10px
		box-shadow: none
		border: 1px solid var(--middleGray)
		font-family: Raleway
		font-size: 1rem
		line-height: 100%
		font-weight: 400
		width: 100%
		height: 100%
		transition: 0.15s border ease-in-out
		background-color: var(--tools-input-background)
		resize: none
		appearance: none

		&:disabled
			background-color: var(--veryLightGray) !important
			border-color: var(--middleGray) !important
			opacity: 0.4

		&:focus
			outline: none
			border-color: var(--middle-gray)

	.DateRangePicker, .SingleDatePicker
		width: 100%
		&_picker
			z-index: 2
		.DateRangePickerInput,.SingleDatePickerInput
			border: none
			background: none
			width: 100%
			.DayPicker, .CalendarMonthGrid, .CalendarMonth
				background: var(--white)
			.CalendarDay
				&__default
					background: var(--white)
					color: var(--dark)
					border-color: var(--background)
				&__today
					background: var(--highlightLighter)
				&__selected
					background: var(--blue)
					color: white
				&__blocked_out_of_range
					opacity: 0.3
				&__blocked_calendar
					opacity: 0.3
			.DayPickerKeyboardShortcuts_buttonReset
				display: none
			.DayPickerNavigation_button__default
				background: var(--background)
				border-color: var(--background)
			.CalendarMonth_caption, .CalendarMonth_caption strong
				color: var(--black)
			.DateInput
				width: calc(50% - 15px)
				background: none
				&_fang
					display: none
			.DateRangePickerInput_arrow
				width: 30px
				display: inline-flex
				justify-content: center
		.SingleDatePickerInput
			.DateInput
				width: 100%
				&_fang,&_screenReaderMessage
					display: none

	.error-icon
		display: none

	&.input-invalid
		input
			border-color: var(--invalid)
			//padding-top: 8px
			//padding-bottom: 16px

		.input-wrapper
			animation: .5s bounce

		.error-message
			position: absolute
			font-size: 10px
			left: 11px
			bottom: 5px
			color: var(--invalid)
			display: none // Do not show

		.error-icon
			z-index: 2
			background: var(--invalid)
			position: absolute
			right: 0
			top: 0
			height: 100%
			width: 54px
			display: flex
			justify-content: center
			align-items: center
			border-radius: 0px 13px 13px 0px
			transform-style: preserve-3d
			transform-origin: center right
			animation: 0.2s flip-in ease-in-out

			.icon
				display: inline-block
				width: 150px
				transform: scale(0.25)

				.stroke
					stroke: white
				.circular
					width: 150px

				.circular circle.path
					stroke-dasharray: 330
					stroke-dashoffset: 0
					stroke-linecap: round
					opacity: 0.4
					animation: 0.3s draw-circle ease-out

				.cross
					stroke-width: 6.25
					stroke-linecap: round
					position: absolute
					top: 54px
					left: 54px
					width: 40px
					height: 40px

					.first-line
						animation: 0.2s draw-first-line ease-out
					.second-line
						animation: 0.2s draw-second-line ease-out

	.onedash-custom-select
		&__control
			min-height: 54px
			border-radius: 15px
			background-color: var(--tools-input-background)
			border: 1px solid var(--lightGray)
			box-shadow: none
			&:hover, &--is-focused
				border-color: var(--middle-gray)

		&__placeholder
			font-family: Raleway
			font-size: 13px
			line-height: 100%
			font-weight: 400

		&__value-container
			padding: 10px 15px
		&__menu
			background: var(--tools-input-background)
			z-index: 2
		&__option
			font-family: Raleway
			font-size: 13px
			line-height: 100%
			&--is-focused
				background: var(--lightGray)
			&:hover
				background: var(--veryLightGray)
	&.onedash-verification-input
		width: auto

		.button-spinner
			position: absolute
			z-index: 2
			left: 50%
			top: 50%
			transform: translateX(-50%) translateY(-50%)
			& > div
				background-color: var(--orange)

		&.disabled .display
			background-color: var(--veryLightGray)
			pointer-events: none
			color: var(--lightGray)
		.display-list
			display: inline-block
			position: relative
			display: flex
			border-radius: 15px
			height: 41px
		input
			position: absolute
			border: none
			font-size: 15px
			text-align: center
			background-color: transparent
			outline: none
			border: none
			padding: 0px
			width: auto
			height: 100%
			width: 32px
			top: 0px
			bottom: 0px
			z-index: 1

		.display
			border: 1px solid var(--lightGray)
			background-color: var(--tools-input-background)
			border-right: none
			width: 32px
			height: 100%
			display: flex
			align-items: center
			justify-content: center
			font-size: 15px
			position: relative
			&:last-child
				border-right: 1px solid var(--lightGray)
				border-radius: 0px 15px 15px 0px
				.shadows
					border-radius: 0px 15px 15px 0px
			&:first-child, &:first-child .shadows
				border-radius: 15px 0px 0px 15px

			.shadows
				position: absolute
				left: -1px
				top: -1px
				bottom: 0
				right: 0
				border: 2px solid #fd7e14
				width: calc(100% + 2px)
				height: calc(100% + 2px)
				z-index: 1
				transition: 0.15s all ease-in-out

	&.onedash-single-file-uploader
		padding: 10px 0px
		position: relative
		width: 100%

		.image-upload-area
			width: 100%
			height: 200px
			cursor: pointer
			&:hover .dropzone i, &:hover .dropzone svg path, &:hover .dropzone .content-placeholder
				color: var(--highlight)
				fill: var(--highlight)

			.dropzone
				position: relative
				border: 1px solid var(--lightGray)
				border-radius: 4px
				display: flex
				justify-content: center
				align-items: center
				flex-direction: column
				background: var(--veryLightGray)
				width: 100%
				height: 100%

				&:focus
					outline: none
					border-color: var(--highlight)

				&.accept
					border-color: var(--highlight)
					i, svg path, span
						color: var(--highlight)
						fill: var(--highlight)
				&.reject
					border-color: var(--invalid)

				.content-placeholder
					color: var(--gray)
					font-size: 0.9rem
					padding: 10px
					text-align: center

		.file
			background: var(--veryLightGray)
			width: 100%
			height: 100%
			border-radius: 4px
			display: flex
			justify-content: center
			align-items: center
			span, i, svg path
				color: var(--middle-gray)
				fill: var(--middle-gray)
		.image, .file
			width: 100%
			height: 200px
			flex-basis: 100px
			flex-shrink: 0
			position: relative
			object-fit: cover

			&:before
				position: absolute
				content: ''
				border-radius: 0px 0px 0px 40%
				background: white
				background: var(--background)
				width: 46px
				height: 46px
				right: -7px
				top: -16px

			.progress-bar
				height: 5px
				background: var(--lightGray)
				width: 100%
				position: absolute
				bottom: 0
				border-bottom-left-radius: 4px
				border-bottom-right-radius: 4px

				span
					height: 100%
					background-color: var(--highlight)
					display: block
					border-bottom-left-radius: 4px
					border-bottom-right-radius: 4px

			img
				width: 100%
				height: 100%
				border-radius: 4px
				box-shadow: 0px 0px 3px rgba(0,0,0,.5)
				object-fit: cover

			.delete-btn
				position: absolute
				right: 0px
				top: 0px
				cursor: pointer
				transition: 0.15s all ease-in-out
				background: none
				border: none
				&:focus
					outline: none
				&:hover
					opacity: 0.7
				i
					font-size: 15px
					color: var(--invalid)
					path
						fill: var(--invalid)
	&.onedash-boolean
		--duration: 0.4s

		.onedash-label
			max-width: 50px

		&.has-children
			display: inline-flex
			align-items: center
			.onedash-children
				font-size: 13px
				line-height: 100%
				font-weight: 400

		.checker
			display: block
			height: 32px
			width: 60px
			box-sizing: content-box
			padding: 5px
			border-radius: 4px
			transition: transform var(--duration) ease
			cursor: pointer
			flex-shrink: 0
			margin-right: 10px
			position: relative

		.checkmark
			width: 24px
			height: 24px
			transition: transform var(--duration) ease
			transform: translateX(-3px)
			position: relative

			svg
				display: block
				background: var(--veryLightGray)
				transform: translateX(10px)
				border-radius: 5px
				margin-top: 4px

				transition: background-color var(--duration) ease, transform calc(var(--duration) * 1.5) ease

				path
					// opacity: 0
					stroke-dasharray: 90 90
					stroke-dashoffset: 90
					transition: stroke-dashoffset calc(var(--duration) / 3) linear calc(var(--duration) / 3)

		.checkbox
			position: absolute
			top: 0
			left: 0
			opacity: 0.4
			visibility: hidden

			&:checked
				~ .checkmark
					transform: translate(60px)

					svg
						background: var(--blue)
						transform: translate(-30px)
						path
							stroke-dashoffset: 0

		.check-bg
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			border-radius: inherit
			background: var(--tools-input-background)
			border-radius: 10px
			border: 1px solid var(--lightGray)
			box-sizing: border-box

			&:before,
			&:after
				content: ""
				position: absolute
				top: 0
				left: 0
				height: 100%
				width: 100%
				border-radius: inherit

			&:after
				background: var(--tools-input-background)

			&:focus
				outline: none
				border-color: var(--middle-gray)

		.checker.checker
			&:active
				transform: scale(0.85)
				transition-duration: calc(var(--duration) / 2)

				.check-bg::before
					transform: translateY(0) scale(0.8)
					opacity: 0.2

.onedash-form
	.form-buttons
		margin-top: 20px
	.onedash-button
		height: 54px
		border-radius: 15px
		border: none
		padding: 18px 30px
		cursor: pointer
		text-transform: uppercase
		font-weight: 900
		transition: 0.15s all ease-in-out

		&.form-submit-btn
			background: var(--blue)
			color: white
			margin-left: 10px

			&:disabled
				color: var(--middle-gray)
				background: var(--lightGray)
				pointer-events: none

		&:hover
			opacity: 0.7
.onedash-button-v2
	padding: 12px 20px
	background: var(--highlight)
	border-radius: 10px
	font-weight: bold
	border: 2px solid var(--highlight)
	color: var(--white)
	transition: 0.15s all ease-in-out
	&:hover
		background: none
		color: var(--text)
	&.link
		background: none
		dispay: inline
		color: var(--highlight)
		padding: 0px
		border: none
		margin: 0px
		font-size: 1rem
		font-weight: normal
		text-decoration: underline
		&:hover
			opacity: 0.7
	&.disabled
		background: var(--lightGray)
		color: var(--gray)
		pointer-events: none
		border-color: var(--lightGray)

	&.icon
		padding: 3px 5px
		margin: 0px
		i
			color: white
			font-size: 16px
		&:hover i
			color: var(--highlight)

.dashboard .onedash-form-v2-component
	&.onedash-boolean
		.checkbox:checked ~ .checkmark svg
			background: var(--highlight)
		.check-bg::after
			background: var(--tools-input-background)

	&.onedash-input input, &.onedash-select select
		background-color: var(--tools-input-background)
		input
			background-color: var(--tools-input-background)
			color: var(--text)
	&.onedash-date-picker
		input
			background-color: var(--tools-input-background)
			color: var(--text)
	&.focused input
		border-color: var(--highlight)

@media print
	.onedash-form-v2-component
		select, input
			border: none
			padding: 5px
			border-radius: 0px
			background: none !important
			background-color: none !important
			&::placeholder
				opacity: 0

		&.onedash-boolean
			.check-bg::after
				background: none !important

@keyframes flip-in
	0%
		transform: rotateY( 90deg )
		opacity: 0
	100%
		opacity: 1
		transform: rotateY( 0deg )
@keyframes bounce
	0%
		transform: translateX(0px)
		timing-function: ease-in

	37%
		transform: translateX(5px)
		timing-function: ease-out

	55%
		transform: translateX(-5px)
		timing-function: ease-in

	73%
		transform: translateX(4px)
		timing-function: ease-out

	82%
		transform: translateX(-4px)
		timing-function: ease-in

	91%
		transform: translateX(2px)
		timing-function: ease-out

	96%
		transform: translateX(-2px)
		timing-function: ease-in

	100%
		transform: translateX(0px)
		timing-function: ease-in
