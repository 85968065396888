.table-overview {
	.room {
		h1 {
			text-align: center;
			text-transform: uppercase;
			margin-top: 40px;
		}
		.tables {
			display: flex;
			flex-wrap: wrap;

			justify-content: center;
			position: relative;
			max-width: 900px;
			margin-left: auto;
			margin-right: auto;

			.table {
				width: 120px;
				height: 170px;
				background: #34c763;
				border-radius: 10px;
				margin: 5px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				cursor: pointer;
				padding: 5px 10px;
				position: relative;

				&.vibrate {
					animation: 1s shake linear infinite;
				}
				&.clone {
					position: absolute;
				}

				.overlay {
					height: 100%;
					width: 100%;
					position: absolute;
					left: 0;
				}

				&.blocked {
					background: #ec6d66;
				}

				h2,
				p {
					margin: 0px;
					text-align: center;
				}
				.state {
					text-transform: uppercase;
				}

				h2 {
					font-size: 1.2rem;
				}
				.people-location {
					margin-top: 5px;
				}
			}
		}
	}
}

#root .change-places {
	.onedash-select {
		max-width: 100%;
		width: 100%;
		margin: 5px 0px;
	}
	.onedash-button {
		margin: 20px 0px 0px 0px;
		width: 100%;
	}
	.popover-close {
		display: none;
	}
}
@keyframes shake {
	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%,
	60% {
		transform: translate3d(4px, 0, 0);
	}
}
