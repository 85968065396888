.calendar-page
	display: grid
	grid-template-columns: 1fr 420px
	height: calc(var(--wh) * 100 - 75px)
	@media screen and ( max-width: 1400px)
		grid-template-columns: 1fr

	.content
		margin: 30px 0px 20px 30px

	.calendar
		@media screen and ( max-width:  720px)
			.calendar-body-wrapper
				max-height: calc(var(--wh) * 100 - 270px)

#details-sidebar

	.booking-details
		.btn-split
			display: grid
			grid-template-columns: 1fr 1fr
			column-gap: 10px
		.btn
			font-size: 1rem
			padding: 10px 5px
			border-radius: 4px
			&.accept
				background: var(--tools-success)
				border-color: var(--tools-success)
			&.decline
				background: var(--invalid)
				border-color: var(--invalid)
			&:hover
				background: none
				&.decline
					color: var(--invalid)
				&.accept
					color: var(--tools-success)

	.badge
		border-radius: 4px
		padding: 5px
		margin: 2.5px 0px
		display: inline-block
	.warning, .success
		padding: 10px
		border-radius: 8px
		&.warning
			background: #ccd3274d
			color: #b6ae2e
			border: 1px solid #b6ae2e
		&.success
			background: #25cc892f
			color: #25cc88
			border: 1px solid #25cc88

	.onedash-form-v2-component
		max-width: 100%
	&.error h1
		text-align: center
		color: var(--invalid)

	.btn
		border: 3px solid var(--highlight)
		border-radius: 0px
		padding: 20px 40px
		background: var(--highlight)
		text-transform: uppercase
		font-size: 1.2rem
		font-weight: bold
		color: white

		&:disabled
			background: var(--lightGray)
			color: var(--middleGray)
			border-color: var(--lightGray)

		&:hover
			background: none
			color: var(--highlight)
	.event-details
		h3
			margin-top: 0px !important
	.event-details, .edit-details
		padding: 40px 15px 40px 40px
		height: calc(100% - 148px)

		& > .btns
			position: absolute
			top: 55px
			right: 70px
			display: flex

			button
				cursor: pointer
				margin-left: 10px
			i
				transition: 0.15s all ease-in-out
				&:hover
					opacity: 0.7
			i
				font-size: 20px
				color: var(--gray)

		h1,h2,h3
			margin: 0px
		h3
			color: var(--gray)
			font-size: 0.9rem
			margin: 15px 0px 0px 0px
		h2
			font-size: 1.3rem
			margin-top: 0px
		h1
			font-size: 1.8rem
			color: var(--highlight)
			margin: 20px 0px 30px 0px
			font-weight: 900

		.detail-content,.edit-content
			max-height: calc(100% - 105px)
			overflow-y: auto
			padding-right: 10px

			&::-webkit-scrollbar
				width: 4px
				height: 2px

			&::-webkit-scrollbar-button
				width: 0px
				height: 0px

			&::-webkit-scrollbar-thumb
				background: #e7e7e7
				border: 0px none #ffffff
				border-radius: 50px

			&::-webkit-scrollbar-track
				background: none
				border: 0px none #ffffff
				border-radius: 50px

			&::-webkit-scrollbar-corner
				background: transparent

		.edit-content
			max-height: calc(100% - 25px)
			min-height: calc(100% - 25px)
			padding-right: 0px
			padding-left: 0px
	.list
		.item
			margin: 15px 0px
			&:first-of-type
				margin-top: 0px
			.label
				font-weight: bold
				margin: 0px
				font-size: 0.8rem
			.value
				margin: 0px
				margin-top: 2.5px

	.registered-users
		ul
			padding: 10px 0px 20px 20px
			li
				list-style: square
				margin: 5px 0px
	img
		width: 100%
		object-fit: contain
	.Collapsible
		margin-top: 20px
		.Collapsible__trigger
			text-transform: uppercase
			font-weight: bold
			font-size: 1.2rem
			& > h4
				margin-bottom: 10px
			h4,h2
				display: flex
				justify-content: space-between
				font-weight: 900
			&.is-disabled i
				display: none
			&:hover:not(.is-disabled)
				h4,h2,i
					cursor: pointer
					opacity: 0.5
			h2,h4,i
				transition: 0.15s all ease-in-out

			i
				transition: 0.15s all ease-in-out
				margin-right: 10px
			&.is-open i
				transform: rotate(180deg)

	.edit-details
		padding: 40px 20px 40px 20px
		+ .btn
			width: 100%
		.Collapsible__contentInner,.Collapsible__trigger h2
			padding: 0px 20px
		.section
			margin: 20px 0px
			padding: 0px 20px
		h1
			padding: 0px 20px
			margin-top: 10px
		h2
			text-transform: uppercase
			font-weight: 900
			font-size: 1.2rem
			margin-bottom: 10px
		.day-selection
			margin: 7.5px 0px
			.btns
				display: flex
				justify-content: space-between
				button
					background: var(--light-gray)
					height: 40px
					width: 40px
					border-radius: 8px
					cursor: pointer
					transition: 0.15s all ease-in-out
					&:hover
						opacity: 0.8
					&.active
						background: var(--highlight)
						color: white
			.label
				display: block
				font-size: 11px
				color: var(--middle-gray)
				margin: 0px 0px 5px 5px

		.onedash-range-slider
			padding-left: 20px
			padding-right: 20px
			.input-range__label--min .input-range__label-container
				left: 0
			.input-range__label--max .input-range__label-container
				left: 0

		.onedash-label .required
			display: none
		.onedash-form-v2-component
			margin: 7.5px 0px
		.item
			margin: 7.5px 0px
			.label
				display: block
				font-size: 11px
				color: var(--middle-gray)
				margin: 0px 0px 0px 5px
		.split
			display: grid
			grid-template-columns: 1fr 1fr
			grid-gap: 0px 20px
