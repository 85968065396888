.onedash-table-v2
	background: var(--background)
	margin: 10px
	border: 1px solid var(--veryLightGray)
	border-radius: 10px
	font-size: 14px
	box-shadow: 0px 0px 3px rgba(0,0,0,0.1)
	.table-head
		padding: 20px 20px
		font-weight: bold
		gap: 0px 10px
		& > div
			color: var(--highlight)

		.sorting-icons
			display: inline-flex
			align-items: center
			margin-left: 5px

			.sort-up, .sort-down
				background: var(--veryLightGray)
				margin: 0px 1px
				border-radius: 2px
				width: 15px
				height: 15px
				display: flex
				justify-content: center
				align-items: center
				cursor: pointer
				transition: 0.15s all ease-in-out
				&.selected
					background: var(--highlight)
				&:hover:not(.selected)
					background: var(--lightGray)

				span
					display: block
					width: 0
					height: 0
					border-left: 5px solid transparent
					border-right: 5px solid transparent
			.sort-up span
				border-bottom: 5px solid var(--middleGray)
			.sort-down span
				border-top: 5px solid var(--middleGray)

			.sort-up.selected span
				border-bottom: 5px solid white

			.sort-down.selected span
				border-top: 5px solid white
	.info-message
		padding: 10px 20px
		font-style: italic
		text-align: center
	.onedash-form-v2-component
		margin: -5px 0px
		input, select
			padding: 8px 10px
	.row
		border-top: 1px solid var(--veryLightGray)

		.right-icon
			display: flex
			justify-content: flex-end
			i
				color: var(--lightGray)
				margin-top: 2px
				font-size: 16px

		&.clickable
			cursor: pointer

		.inner
			margin: 5px 10px
			border-radius: 10px
			padding: 7.5px 10px
			transition: .1s all ease-in-out
			gap: 0px 10px
			align-items: center
		&.selected .inner
			background: var(--highlightLight)

		@media (hover: hover) and (pointer: fine)
			&:not(.selected):hover .inner
				background: #37468f07

	.select-container
		display: block
		position: relative
		cursor: pointer
		user-select: none
		input
			display: none
		input:checked ~ .checkmark
			background-color: var(--highlight)

		&:hover .checkmark
			background-color: var(--lightGray)

		input:checked ~ .checkmark:after
			display: block
		.checkmark
			position: absolute
			top: 0
			left: 0
			height: 15px
			width: 15px
			background-color: #eee
			border-radius: 4px
			transition: .1s all ease-in-out

			&:after
				content: ""
				position: absolute
				display: none

			&:after
				left: 5px
				top: 2.5px
				width: 3px
				height: 7px
				border: solid white
				border-width: 0 2px 2px 0
				-webkit-transform: rotate(45deg)
				-ms-transform: rotate(45deg)
				transform: rotate(45deg)

	&.is-mobile
		.right-icon
			display: flex
			justify-content: center
			align-items: center
			.right-chevron::before
				top: 0

		.column
			margin: 5px 0px
			.label
				font-weight: bold

.onedash-dialog
	.onedash-table-v2
		margin: 0px
		box-shadow: none
