.onedash-dialog-v2
	display: none
	font-size: 14px

	&.max-400
		.dialog-wrapper .dialog
			max-width: 400px

	.dialog-wrapper
		position: fixed
		z-index: 501
		width: 100vw
		height: 100vh
		left: 0
		top: 0
		display: flex
		justify-content: center
		align-items: center

		.dialog
			background: var(--background)
			border-radius: 6px
			padding: 20px
			display: flex
			flex-direction: column
			justify-content: space-between
			position: relative
			width: calc(100% - 100px)
			max-width: 600px
			animation: scaleIn 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards
		.dialog-title
			color: var(--highlight)
			font-size: 1.5em
			padding-bottom: 5px
		.close-btn
			background: none
			border: none
			position: absolute
			right: 25px
			top: 15px
			font-size: 35px
			transform: rotate(45deg)
			transition: 0.15s all ease-in-out
			cursor: pointer
			&:focus
				outline: none
			&:hover
				opacity: 0.7
				transform: rotate(0deg)

		.content
			max-height: calc((var(--wh) * 100) - 150px)
			overflow-y: auto
			padding-right: 10px

			&::-webkit-scrollbar
				width: 4px
				height: 2px

			&::-webkit-scrollbar-button
				width: 0px
				height: 0px

			&::-webkit-scrollbar-thumb
				background: #e7e7e7
				border: 0px none #ffffff
				border-radius: 50px

			&::-webkit-scrollbar-track
				background: none
				border: 0px none #ffffff
				border-radius: 50px

			&::-webkit-scrollbar-corner
				background: transparent

	.dialog-bg
		animation: fadeBGIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards
		position: fixed
		width: 100vw
		height: 100vh
		left: 0
		top: 0
		z-index: 500

	&.visible
		display: block

	&.is-closing
		display: block
		.dialog
			animation: scaleOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards
		.dialog-bg
			animation: fadeBGOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards

	&.prompt
		.dialog-wrapper
			z-index: 503
		.dialog-bg
			z-index: 502
		.dialog
			max-width: 400px
		.buttons
			justify-content: flex-start

	.buttons
		display: flex
		justify-content: flex-end
		&.split
			display: flex
			flex-wrap: wrap
			justify-content: space-between
			& > div
				display: flex

		button
			padding: 6px 20px
			border: none
			border-radius: 5px
			font-size: 1em
			font-weight: bold
			cursor: pointer
			transition: 0.15s all ease-in-out
			margin: 5px
			margin-right: 0px
			border: 2px solid var(--middleGray)
			&:hover
				background: var(--middleGray)
				color: white
			&:disabled
				border: 2px solid #dbdbdb
				background: #dbdbdb
				color: #a5a5a5
				pointer-events: none
			&:first-of-type
				margin-left: 0px

			&.no-style
				padding: 0px
				border-radius: 0px
				font-weight: normal
				&:hover, &:focus
					opacity: 0.7
				&:focus
					border: none !important
				&.danger
					color: var(--invalid)
			&.submit:not(:disabled), &.yes:not(:disabled)
				background: var(--highlight)
				color: white
				border: 2px solid var(--highlight)
				&:hover
					background: var(--highlightLight)
					color: var(--highlight)
			&.cancel:not(:disabled), &.no:not(:disabled)
				background: white
				color: black
				border: 2px solid #dbdbdb
				&:hover
					background: #dbdbdb
			&:focus
				outline: none
				border-color: black !important

			&.decline:not(:disabled)
				background: white
				color: white
				background: var(--invalid)
				border: 2px solid var(--invalid)
				&:hover
					background: none
					color: var(--invalid)

	h1
		font-size: 1.3em
		margin: 0px 0px 10px 0px
		line-height: 1.1em
	h2
		font-size: 1.1em
		margin: 10px 0px
		line-height: 1em

	&.sidebar
		.dialog-wrapper
			z-index: 500
		&.is-closing
			.dialog
				animation: fadeLeftOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards !important
		.dialog-bg
			display: none
		.close-btn
			transform: rotate(0deg)
			top: 42px
			right: 35px
			i
				font-size: 20px
				color: var(--gray)
			&:hover
				transform: rotate(45deg)

		.dialog-wrapper
			position: fixed
			right: 0px
			left: auto
			top: 50px
			bottom: 0
			width: 400px
			display: block

			@media screen and ( max-width:  720px)
				top: 0px
				width: 100%

			.dialog
				height: calc(100% - 50px)
				border-radius: 0px
				width: 100%
				position: relative
				box-shadow: var(--big-shadow)
				padding: 0px
				animation: fadeLeftIn 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards

				@media screen and ( max-width:  720px)
					height: 100%
					max-width: 100%
				.content
					width: 100%
					max-height: none
					height: 100%
					padding: 0px
					display: flex
					flex-direction: column
					justify-content: space-between

	@keyframes scaleOut
		0%
			transform: scale(1)
			opacity: 1

		100%
			transform: scale(0)
			opacity: 0.1

	@keyframes scaleIn
		0%
			transform: scale(0)
			opacity: 0.1

		100%
			transform: scale(1)
			opacity: 1

	@keyframes fadeBGIn
		0%
			background: transparent
			backdrop-filter: blur(0px)
			-webkit-backdrop-filter: blur(0px)

		100%
			background: rgba(0,0,0,0.5)
			backdrop-filter: blur(4px)
			-webkit-backdrop-filter: blur(4px)

	@keyframes fadeBGOut
		0%
			background: rgba(0,0,0,0.5)
			backdrop-filter: blur(4px)
			-webkit-backdrop-filter: blur(4px)

		100%
			background: transparent
			backdrop-filter: blur(0px)
			-webkit-backdrop-filter: blur(0px)

	@keyframes fadeLeftIn
		0%
			transform: translateX(110%)
			opacity: 0.8

		100%
			transform: translateX(0%)
			opacity: 1
	@keyframes fadeLeftOut
		0%
			transform: translateX(0%)
			opacity: 1

		100%
			transform: translateX(110%)
			opacity: 0.9
