.departments-page {
	.service {
		border-bottom: 1px solid var(--middleGray);
		padding: 20px 0px;
		&:last-of-type {
			border-bottom: 0px;
		}
	}
	.split {
		display: flex;
		justify-content: space-between;
		align-items: center;
		button {
			i {
				color: var(--middleGray);
				font-size: 1.4rem;
			}
			&:hover i {
				color: var(--highlight);
			}
		}
	}
}
