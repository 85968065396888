.onedash-calendar
	$support-border: 1px solid var(--lightGray)

	max-width: 100%
	@media (min-width: 720px)
		width: calc(100% - 20px) !important

	@media (max-width: 720px)
		margin: 0px 10px
	.onedash-calendar-content
		background: var(--white)
		border-radius: 10px
		box-shadow: var(--big-shadow)
	&-toolbar
		display: flex
		padding-bottom: 20px

		.toolbar-btn
			padding: 7.5px 20px
			text-transform: uppercase
			font-size: 1rem
			cursor: pointer
			border-radius: 7.5px
			box-shadow: none
			border: 2px solid
			outline: none
			font-weight: 700
			transition: 0.15s all ease-in-out
			&:disabled
				pointer-events: none
				background: var(--lightGray)!important
				border-color: var(--lightGray)!important
				color: var(--middleGray) !important
			&.highlight-btn
				background: var(--highlight)
				border-color: var(--highlight)
				color: #fff
				&:hover
					color: var(--highlight)
			&:hover
				background: none
				color: black

		&> div
			flex: 1
			display: flex
			align-items: center

		.center
			text-align: center
			justify-content: center

			h1
				color: var(--highlight)
				margin: 0px
				font-weight: 900
				font-size: 1.5rem

		.right
			justify-content: flex-end

			& > button
				padding: 10px
				&:hover i
					color: var(--dark)
				i
					color: var(--middleGray)

			.period-switch
				border-radius: 5px
				display: flex
				align-items: center

				.nav-btn
					padding: 5px 10px
					transition: 0.15s all ease-in-out
					cursor: pointer
					background: none
					border: none
					color: var(--gray)
					font-size: 1.3rem
					transform: scaleY(2)
					margin-top: -5px

					&:hover, &:disabled
						opacity: 0.6

		.right
			justify-content: flex-end

		@media screen and ( max-width: 720px )
			justify-content: space-between
			h1
				color: var(--highlight)
				margin: 0px
				font-weight: 900
				font-size: 1.5rem
			.nav-btn
				padding: 5px 10px
				transition: 0.15s all ease-in-out
				cursor: pointer
				i
					color: var(--gray)
					font-size: 1.3rem
					font-weight: bold
					margin-top: 5px

	&-head
		display: grid
		grid-template-rows: 75px
		background: var(--veryLightGray)
		border-radius: 10px 10px 0px 0px

		&-legend
			width: 100%
			height: 100%
		&-day-head
			display: flex
			flex-direction: column
			justify-content: center
			align-items: center
			padding: 10px 0px
			border-left: $support-border
			&.first
				border-left: none
				border-top-left-radius: 10px
			&.last
				border-top-right-radius: 10px

			.same-day
				color: var(--highlight)

			span
				position: relative
				font-size: 2rem
				font-weight: 600
				z-index: 1
				color: var(--dark)
				&.small
					font-size: 1.3rem
					font-weight: bold
					&::after
						width: 30px
						height: 30px
						margin-top: -3px
						margin-left: -9px
			h2
				font-weight: normal
				color: var(--middleGray)
				margin: 0px
				font-size: 1.1rem

	&-body-wrapper
		max-height: calc(var(--wh) * 100 - 235px)
		overflow-y: auto
		margin-right: -12px
		padding-right: 8px
		width: 100%
		@media (max-width: 720px)
			max-height: calc(var(--wh) * 100 - 200px)
			padding-right: 0px

		&::-webkit-scrollbar
			width: 4px
			height: 2px

		&::-webkit-scrollbar-button
			width: 0px
			height: 0px

		&::-webkit-scrollbar-thumb
			background: #c2c2c2
			border: 0px none #ffffff
			border-radius: 50px

		&::-webkit-scrollbar-track
			background: none
			border: 0px none #ffffff
			border-radius: 50px

		&::-webkit-scrollbar-corner
			background: transparent

	&-body
		position: relative
		display: grid
		overflow: hidden

		@media print
			grid-template-rows: minmax(calc(var(--wh) * 100 - 235px),auto)

		&-day
			border-left: $support-border
			&.first
				border-left: none
				border-bottom-left-radius: 10px
			&.last
				border-bottom-right-radius: 10px
		&-legend
			display: grid
			justify-content: end
			align-items: center

			p
				margin: 0px
				text-align: right
				color: var(--middleGray)
				margin-right: 15px

				@media screen and ( max-width:  720px)
					font-size: 0.8rem

		&-lines
			&-hour
				position: absolute
				height: 1px
				background: var(--lightGray)
				left: 100px
				width: calc(100% - 100px)
				top: 0px

			&-current
				position: absolute
				height: 1px
				background: var(--highlight)
				left: 90px
				width: calc(100% - 90px)

				&::after
					$size: 7px
					content: ""
					position: absolute
					width: $size
					height: $size
					background: var(--highlight)
					border-radius: 50%
					top: -3px

				span
					position: absolute
					transform: translateX(-100%) translateY(-50%)
					font-size: 0.8rem
					margin-left: -2.5px
					color: white
					background: var(--highlight)
					padding: 5px 10px
					font-weight: bold
					border-radius: 20px

	&-items
		width: calc(100% - 100px)
		position: absolute
		left: 100px
		height: 100%

		button.onedash-calendar-item
			cursor: pointer
			transition: 0.15s all ease-in-out
			&:hover
				opacity: 0.8
		.onedash-calendar-item
			position: absolute
			padding: 0px
			&.active
				pointer-events: none
				opacity: 0.5
			div
				color: inherit
			& > div
				position: relative

				$margin: 5px
				height: calc(100% - #{$margin * 2} - 20px)
				width: calc(100% - #{$margin * 2} - 23px)
				margin: $margin
				border-left: 3px solid
				padding: 10px
				border-radius: 0px 10px 10px 0px
				& > div
					display: flex
					flex-direction: column
					justify-content: space-between
					height: 100%
					overflow: hidden

				h2
					font-size: 1rem
					color: inherit
					margin: 0px
					text-overflow: ellipsis
					overflow: hidden
					text-align: left
				p
					margin: 0px
					text-overflow: ellipsis
					overflow: hidden
					text-align: left
				.time
					font-weight: bold
					color: inherit

				.description
					font-weight: normal
					font-size: 0.8rem
					color: inherit
