#root .dashboard {
	display: grid;
	grid-template-columns: 80px 1fr;
	grid-template-rows: 65px 1fr;
	width: 100%;
	height: calc(var(--wh) * 100);
	transition: 0.3s all ease-in-out;
	@media (min-width: 720px) {
		&.has-sidebar-v2 .main-content {
			width: calc(100% - 420px);
		}
	}
	@media print {
		height: auto !important;
		overflow-y: initial !important;
		grid-template-columns: 1fr !important;
		grid-template-rows: 1fr !important;
		display: block;
		.main-content {
			height: auto !important;
			padding: 0px !important;
			overflow-y: initial !important;
		}
	}

	.head-bar {
		grid-column: 1/4;
	}
	.main-content {
		padding: 20px;
		margin: 0px;
		position: relative;
		transition: 0.3s all cubic-bezier(0.165, 0.84, 0.44, 1);
		height: calc(var(--wh) * 100 - 105px);
		overflow-y: auto;
		width: calc(100% - 40px);

		&.has-sidebar {
			@media (min-width: 720px) {
				width: calc(100% - 400px);
			}
		}

		& > .spinner-container {
			height: calc(100vh - 200px);
		}

		.info-text {
			text-align: center;
			max-width: 270px;
			font-size: 1.3rem;
		}

		&.has-toolbar {
			.sidebar-wrapper {
				top: 130px;
				height: calc(100vh - 130px);
			}
		}

		.head-toolbar {
			width: 100%;
			min-height: 50px;
			margin: -20px -20px 0px -20px;
			background: var(--highlight);
			box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
			padding: 5px 20px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			margin-bottom: 20px;

			&.sticky {
				position: sticky;
				top: 65px;
				z-index: 10;
			}

			&.split {
				justify-content: space-between;
				& > div {
					&:nth-child(1) {
						flex: 1;
					}
					display: flex;
					align-items: center;
				}
			}
			.onedash-form-v2-component.onedash-select {
				.onedash-custom-select {
					&__control {
						min-height: auto;
						border-radius: 10px;
					}
					&__value-container {
						padding: 5px 10px;
					}
				}
			}

			.onedash-button-v2 {
				background-color: var(--background);
				border-color: var(--background);
				color: var(--highlight);

				i {
					color: var(--highlight);
				}
				&:hover {
					background: none;
					color: var(--background);
					i {
						color: var(--background);
					}
				}
			}
			.export-btn {
				padding: 5px 10px;
				display: flex;
				align-items: center;
				gap: 0.25rem;
			}

			.onedash-button {
				padding: 7.5px 10px;
				margin: 5px;

				&:hover {
					color: white;
				}
			}

			.onedash-form-component {
				margin: 0px;
				.onedash-select {
					margin-top: 0px;
				}
				.onedash-select__control,
				select {
					border: 2px solid white !important;
				}
				.onedash-select__control {
					height: 30px;
				}

				.onedash-select__placeholder {
					font-weight: bold;
					color: white;
				}
				.onedash-select__single-value {
					font-weight: bold;
					color: white;
				}
				.onedash-select__indicator svg * {
					color: white;
				}

				.onedash-select__menu-list {
					background: var(--background);
				}
				select {
					color: white;
					margin: 5px;
					width: calc(100% - 10px);
					height: 34px;
					min-width: 100px;
				}
			}
		}
	}

	@media (max-width: 720px) {
		display: block;
		.main-content {
			width: 100%;
			margin-left: 0px;
			margin-top: 0px;
			padding-top: 20px;
			padding-left: 0px;
			.head-toolbar {
				margin: 0px;
				width: calc(100% - 20px);
				background: var(--nav-bar);
				padding: 5px 10px;
				margin-bottom: 20px;
			}
			&.has-toolbar {
				margin: 0px;
				padding-top: 0px;
			}
		}
	}
}
