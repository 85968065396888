#root .fly-fishing-form
	.grid
		display: grid
		grid-template-columns: 1fr 1fr
		gap: 0px 20px
	.list
		.grid p
			margin: 5px 0px

#root .fly-fishing-stats
	.toolbar
		display: flex
		flex-wrap: wrap
		justify-content: space-between
		margin: 0px 10px
